import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import GuiInfoBox from '../../components/gui/infoBox';

@observer
class PaymentReceipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    loadAll = async (props = this.props) => {
        const { currency, amount, title, type, race, raceClassContestant, uuidv4 } = this.props;
        const { paymentStore } = this.props.stores;
        const currentPayments = await paymentStore.load({ query: {
            type,
            race,
            raceClassContestant,
            uuidv4,
        }, skipUpdate: true });
        const currentPayment = currentPayments?.[0];
        this.setState({ currentPayment });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { currentPayment } = this.state;
        const { currency, amount, title, type } = this.props;

        return (
			<div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 20px;'>
                    {/* currency: {currency}
                    amount: {amount}
                    title: {title}
                    type: {type} */}

                    <div class='d-flex flex-row justify-content-center mt-0 position-relative'>
                        <GuiInfoBox
                            stores={this.props.stores}
                            nameMiddle={title}
                            value={util.format(amount, 0)}
                            postfix={'NOK'}
                            styles={`font-size: 2.5em; line-height: 1.0em;`}
                        />
                        <div
                            class={`position-absolute text-success border border-success`}
                            style={`
                                right: -20px;
                                top: -5px;
                                border-radius: 0.5em;
                                padding: 0.25em 0.5em;
                                font-size: 2.0em;
                                rotate: 20deg;
                            `}>
                            <i class={'fa-solid fa-check'} /> Paid
                        </div>
                    </div>

                    <div class='d-flex flex-column'>
                        <div class='d-flex flex-row'>
                            <div class='w-25 font-weight-lighter'>
                                <Text id='paymentReceipt.paidDate'>Paid date</Text>
                            </div>
                            <div class='w-75'>
                                {util.formatDate(currentPayment?.paidDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                            </div>
                        </div>
                        <div class='d-flex flex-row'>
                            <div class='w-25 font-weight-lighter'>
                                <Text id='paymentReceipt.paymentMethod'>Payment method</Text>
                            </div>
                            <div class='w-75'>
                                {currentPayment?.paymentMethod}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default PaymentReceipt;
