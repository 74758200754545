import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class SaleReportStore extends LocalModel {
    constructor() {
        super('saleReport', {
            namePlural: 'saleReports',
            sort: '-reportDate -createdDate',
            limit: 500,
            api: {
                search: {
                    url: '/api/salereports/',
                    params: {
                        limit: 500,
                        sort: '-reportDate -createdDate',
                    },
                },
                load: {
                    url: '/api/salereports/',
                    params: {},
                },
                save: {
                    url: '/api/salereports/',
                    params: {},
                },
                delete: {
                    url: '/api/salereports/',
                    params: {},
                },
            },
        });
    }

    @observable newSaleReport = {};

    @observable saleReport = {};

    @observable saleReports = [];
}

const store = new SaleReportStore();
export default store;
