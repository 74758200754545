import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';

import RaceClassContestantViewProfile from '../../components/raceClassContestant/viewProfile';

import localUtil from '../../lib/util';
import { has } from 'mobx';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

function CircleProgress({ progress = 0, radius = 20, strokeWidth = 8, width = 70, height = 70, fontSize = 12, backgroundColor = '#e6e6e6', color = '#3f51b5' }) {
    const diameter = radius * 2 + strokeWidth * 2;
    const circumference = 2 * Math.PI * radius;
    const strokeOffset = circumference - (progress / 100) * circumference;

    // Green color: #4caf50
    const finalColor = progress > 99 ? '#4caf50' : color;

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${diameter} ${diameter}`}
        >
            {/* Background circle */}
            <circle
                cx={radius + strokeWidth / 2}
                cy={radius + strokeWidth / 2}
                r={radius}
                fill='transparent'
                stroke={backgroundColor}
                strokeWidth={strokeWidth}
            />
            {/* Progress circle */}
            <circle
                cx={radius + strokeWidth / 2}
                cy={radius + strokeWidth / 2}
                r={radius}
                fill='transparent'
                stroke={finalColor}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={strokeOffset}
                strokeLinecap='round'
                style={{ transition: 'stroke-dashoffset 0.5s ease' }}
            />
            {/* Centered text */}
            <text
                x={radius + strokeWidth / 2}
                y={radius + strokeWidth / 2}
                textAnchor='middle'
                dy='.3em'
                fontSize={fontSize}
                fill='#000'
            >
                {progress}%
            </text>
            <text
                x={radius + strokeWidth / 2}
                y={radius + strokeWidth / 2 + fontSize - 3}
                textAnchor='middle'
                dy='.3em'
                fontSize={fontSize / 2}
                fill='#000'
            >
                Hvile
            </text>
        </svg>
    );
}

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

@observer
class RaceView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestants } = raceClassContestantStore;
        const rcc = raceClassContestants.find(e => e.id === parseInt(id, 10));
        raceClassContestantStore.updateKeyValue('raceClassContestant', rcc);
        // const response = await raceClassContestantStore.load(id, false, { query: { addResults: true }, skipUpdate: true });
        // // await raceClassContestantStore.load(id);
        // console.log('response', response)
        // raceClassContestantStore.updateKeyValue('raceClassContestant', response[0]);
        this.calculateRemainingRest(rcc, rcc.raceClass);
    }

    calculateRemainingRest = (rcc) => {
        const { raceClass = {} } = this.props;
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant = {} } = raceClassContestantStore;

        const sortedCheckpoints = raceClass.checkpoints ? raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])) : [];
        let totalRequiredRestMinutes = 0;
        let totRequiredRestForContestant = 0;
        let accumulatedRestTime = 0;

        sortedCheckpoints.forEach((cp, idx) => {
            const rccCp = raceClassContestant?.checkpoints?.find(e => e.checkpoint === cp.md5) || {};
            const isPrologue = cp.isPrologue == 1;
            const isStartline = cp.isStartLine == 1;
            const isFinished = sortedCheckpoints.length === idx + 1;

            const timeInCp = !isFinished && rccCp.timestampIn ? util.dateDiff(rccCp.timestampIn, rccCp.timestampOut || rccCp.timstampScratch) : 0;

            if (timeInCp.seconds > 0) {
                accumulatedRestTime += timeInCp.seconds;
            }

            if (!isPrologue && !isStartline) {
                totalRequiredRestMinutes += cp.requiredRestMinutes ? parseInt(cp.requiredRestMinutes, 10) : 0;
                totRequiredRestForContestant = totalRequiredRestMinutes + (rcc.timeEqualization?.minutes || 0);
            }
        });

        this.setState({
            totalRequiredRestMinutes,
            totRequiredRestForContestant,
            accumulatedRestTime,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            showAnimations = true,
            showResults = false,
            race,
            raceClass = {},
            markerInfo = {},
        } = this.props;
        const { totalRequiredRestMinutes, totRequiredRestForContestant, accumulatedRestTime } = this.state;
        const { appState, userStore, raceClassContestantStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { raceClassContestant = {} } = raceClassContestantStore;
        const { id, bib, firstname, lastname, startTime } = raceClassContestant;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;
        const tdWidth = '40px';

        const sortedCheckpoints = raceClass.checkpoints ? raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])) : [];
        const hasRaceComments = raceClassContestant.results?.filter(e => ['warning', 'timepenalty', 'disqualified', 'scratch'].includes(e.eventType)).length > 0;
        // raceClass.checkpoints && raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name']))

        let firstTime = null;
        let accumulatedTotalTimeUsed = 0;
        let accumulatedTimeUsed = 0;
        let accumulatedStopTime = 0;
        let accumulatedDistance = 0;
        let estimatedSpeed;
        let lastCpIdx = 0;

        if (!this.props.id) {
            return (<>
                <div class='w-100 d-flex justify-content-center'>
                    <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                        <div class='d-flex flex-column justify-content-center align-items-center'>
                            <h1 class='text-center text-muted font-weight-lighter'><Text id='race.no-racecontestant'>No contestant selected</Text></h1>
                        </div>
                    </div>
                </div>
            </>);
        }

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <RaceClassContestantViewProfile
                        stores={this.props.stores}
                        user={raceClassContestant}
                        raceClass={raceClass}
                        markerInfo={markerInfo}
                        leftCornerInfo={<>
                            {/* totalRequiredRestMinutes: {totalRequiredRestMinutes}<br />
                            totRequiredRestForContestant: {totRequiredRestForContestant}<br />
                            accumulatedRestTime: {accumulatedRestTime / 60}<br /> */}
                            {totRequiredRestForContestant > 0 && <>
                                <div class='ml-2'>
                                    <CircleProgress progress={Math.ceil(accumulatedRestTime / 60 / totRequiredRestForContestant * 100)} width={70} height={70} /><br />
                                    {/* <CircleProgress progress={60} width={70} height={70} /><br /> */}
                                </div>
                            </>}
                        </>}
                    />

                    {showResults ? <>
                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mt-3'>
                            <Text id='race.rcc-times'>Race times</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                            <div class='table-responsive' style='font-size: 0.8em;'>
                                <table class='table table-striped table-hover table-sm'>
                                    <thead>
                                        <tr>
                                            <th class='p-0 px-1'><Text id='race.result-cp'>Checkpoint</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-time-in'>Time in</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-time-out'>Time out</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-dogs'>Dogs</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-time-used'>Time used</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-speed'>Speed</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-acc-time-used'>Acc time used</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-time-in-cp'>Time in CP</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-acc-time-in-cp'>Acc time in CP</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-percent-time-in-cp'>% time in CP</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-distance'>Distance</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-total-distance'>Total distance</Text></th>
                                            <th class='p-0 px-1'><Text id='race.result-total-time'>Total time</Text></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedCheckpoints.map((cp, idx) => {
                                            const rccCp = raceClassContestant?.checkpoints?.find(e => e.checkpoint === cp.md5) || {};
                                            const hasLeftCp = rccCp.timestampOut;
                                            const isInCp = rccCp.timestampIn && !rccCp.timestampOut;
                                            const hasScratced = rccCp.timstampScratch;
                                            const isFinished = sortedCheckpoints.length === idx + 1;
                                            const isStart = cp.sortOrder === 1;

                                            const prevCp = !isStart ? sortedCheckpoints[idx - 1] : {};
                                            const prevRccCp = raceClassContestant.checkpoints?.find(e => e.checkpoint === prevCp.md5) || {};

                                            const hasLeftPrevCp = prevRccCp.timestampOut;

                                            const timeInCp = !isFinished && rccCp.timestampIn ? util.dateDiff(rccCp.timestampIn, rccCp.timestampOut || rccCp.timstampScratch) : 0;
                                            const timeFromPrevCp = prevRccCp.timestampOut ? util.dateDiff(prevRccCp.timestampOut, rccCp.timestampIn || rccCp.timstampScratch) : 0;
                                            const speedFromPrevCp = timeFromPrevCp.seconds > 0 ? (cp.distanceFromPrev / timeFromPrevCp.seconds) * 3600 : 15;

                                            if (hasLeftCp) {
                                                estimatedSpeed = speedFromPrevCp;
                                            }
                                            if (isInCp || hasLeftCp) {
                                                lastCpIdx = idx;
                                            }
                                            const isNextCp = lastCpIdx + 1 === idx;
                                            let estimatedTimeFromPrevCp;
                                            let estimatedInTimeNextCp;
                                            if (isNextCp) {
                                                estimatedTimeFromPrevCp = cp.distanceFromPrev / estimatedSpeed * 3600;
                                                estimatedInTimeNextCp = new Date(new Date(prevRccCp.timestampOut).getTime() + estimatedTimeFromPrevCp * 1000); // Convert seconds to milliseconds                                                // Add estimatedTimeFromPrevCp seconds to estimatedInTimeNextCp date.
                                            }


                                            if (timeFromPrevCp.seconds > 0) {
                                                accumulatedTimeUsed += timeFromPrevCp.seconds;
                                                accumulatedTotalTimeUsed += timeFromPrevCp.seconds;
                                            }
                                            if (timeInCp.seconds > 0) {
                                                accumulatedStopTime += timeInCp.seconds;
                                                accumulatedTotalTimeUsed += timeInCp.seconds;
                                            }
                                            if (cp.distanceFromPrev) {
                                                accumulatedDistance += parseFloat(cp.distanceFromPrev);
                                            }
                                            return (<>
                                                <tr>
                                                    <td class='p-0 px-1 text-left'>
                                                        {cp.name}
                                                        {/* isNextCp: {JSON.stringify(isNextCp)} */}
                                                        {/* hasLeftCp: {JSON.stringify(hasLeftCp)} */}
                                                    </td>
                                                    <td class='p-0 px-1 text-center'>
                                                        {!isStart && rccCp.timestampIn ? util.isoDateCompact(rccCp.timestampIn) : ''}
                                                        {hasScratced ? <>
                                                            <i class='fa-solid fa-circle-xmark text-danger mx-1' />
                                                        </> : <>
                                                            {isNextCp && hasLeftPrevCp && estimatedSpeed ? <>
                                                                <span class='font-weight-light font-italic text-muted'>
                                                                    <small><small>Est:</small></small> {util.isoDateCompact(estimatedInTimeNextCp)}
                                                                </span>
                                                            </> : ''}
                                                        </>}
                                                    </td>
                                                    <td class='p-0 px-1 text-center'>{rccCp.timestampOut ? util.isoDateCompact(rccCp.timestampOut) : ''}</td>
                                                    <td class='p-0 px-1 text-center'>{rccCp.dogsOut ? rccCp.dogsOut : ''}</td>
                                                    <td class='p-0 px-1 text-center'>
                                                        {isNextCp && hasLeftPrevCp && estimatedSpeed ? <>
                                                            <span class='font-weight-light font-italic text-muted'>
                                                                <small><small>Est:</small></small> {util.secToHms(estimatedTimeFromPrevCp)}
                                                            </span>
                                                        </> : <>
                                                            {!isStart && timeFromPrevCp ? util.secToHms(timeFromPrevCp.seconds) : ''}
                                                        </>}
                                                    </td>
                                                    <td class='p-0 px-1 text-center'>
                                                        {!isStart && (isInCp || hasLeftCp) && speedFromPrevCp ? `${util.format(speedFromPrevCp, 1)} km/t` : ''}
                                                        {isNextCp && hasLeftPrevCp && estimatedSpeed ? <>
                                                            <span class='font-weight-light font-italic text-muted'>
                                                                <small><small>Est:</small></small> {util.format(estimatedSpeed, 1)} km/t
                                                            </span>
                                                            {/* estimatedTimeFromPrevCp: {util.secToHms(estimatedTimeFromPrevCp)}<br /> */}
                                                            {/* estimatedInTimeNextCp: {util.isoDateCompact(estimatedInTimeNextCp)}<br /> */}
                                                        </> : ''}
                                                    </td>
                                                    <td class='p-0 px-1 text-center'>{!isStart && (isInCp || hasLeftCp) && timeFromPrevCp && accumulatedTimeUsed ? util.secToHms(accumulatedTimeUsed) : ''}</td>
                                                    <td class='p-0 px-1 text-center'>{!isStart && timeInCp ? util.secToHms(timeInCp.seconds) : ''}</td>
                                                    <td class='p-0 px-1 text-center'>{!isStart && timeInCp && accumulatedStopTime ? util.secToHms(accumulatedStopTime) : ''}</td>
                                                    <td class='p-0 px-1 text-center'>{accumulatedTimeUsed ? `${util.format(accumulatedStopTime / accumulatedTimeUsed * 100, 0)}%` : ''}</td>
                                                    <td class='p-0 px-1 text-center'>{cp.distanceFromPrev} km</td>
                                                    <td class='p-0 px-1 text-center'>{accumulatedDistance} km</td>
                                                    <td class='p-0 px-1 text-center'>{util.secToHms(accumulatedTotalTimeUsed)}</td>
                                                </tr>
                                            </>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mt-3'>
                            <Text id='race.rcc-details'>Race details</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                            <div class='d-flex flex-column justify-content-center px-3 mb-2 position-relative mt-2'>
                                <small>
                                    <div class='table-responsive'>
                                        <table class='table table-sm table-striped table-hover'>
                                            <tbody>
                                                <tr>
                                                    <td style='width: 100px;'>
                                                        <Text id='input.starttime'>Start time</Text>
                                                    </td>
                                                    <td>
                                                        {util.isoDate(raceClassContestant.startTime)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Text id='input.timeEqualization'>Time equalization</Text>
                                                    </td>
                                                    <td>
                                                        {util.secToHms(raceClassContestant.timeEqualization?.seconds)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* startTime: {util.isoDate(raceClassContestant.startTime)}<br /> */}
                                    {/* firstStartTime: {util.isoDate(raceClassContestant.firstStartTime)}<br /> */}
                                    {/* lastStartTime: {util.isoDate(raceClassContestant.lastStartTime)}<br /> */}
                                    {/* timeEqualization: {util.secToHms(raceClassContestant.timeEqualization?.seconds)}<br /> */}
                                </small>
                            </div>
                        </div>

                        {hasRaceComments && <>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mt-3'>
                                <Text id='race.rcc-events'>Race events</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-0 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                                <div class='d-flex flex-column justify-content-center px-3 mb-2 position-relative mt-2'>
                                    <small>
                                        <div class='table-responsive'>
                                            <table class='table table-sm table-striped table-hover'>
                                                <thead>
                                                    <tr>
                                                        <th style='width: 100px !important;'><Text id='input.eventtype'>Event Type</Text></th>
                                                        <th><Text id='input.description'>Description</Text></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {raceClassContestant.results.filter(e => ['warning', 'timepenalty', 'disqualified', 'scratch'].includes(e.eventType)).map((rr, idx) => {
                                                        return (<>
                                                            <tr>
                                                                <td class='text-center'>
                                                                    {rr.eventType === 'in' && <>
                                                                        <i class='fa-solid fa-right-to-bracket text-success' />
                                                                    </>}
                                                                    {rr.eventType === 'out' && <>
                                                                        <i class='fa-solid fa-right-from-bracket text-primary' />
                                                                    </>}
                                                                    {rr.eventType === 'scratch' && <>
                                                                        <i class='fa-solid fa-circle-xmark text-danger' />
                                                                    </>}
                                                                    {rr.eventType === 'warning' && <>
                                                                        <i class='fa-solid fa-cards-blank text-warning' /><br />
                                                                        <small>
                                                                            <Text id='race.event-warning'>warning</Text>
                                                                        </small>
                                                                    </>}
                                                                    {rr.eventType === 'disqualified' && <>
                                                                        <i class='fa-solid fa-user-xmark text-danger' /><br />
                                                                        <small>
                                                                            <Text id='race.event-disqualified'>disqualified</Text>
                                                                        </small>
                                                                    </>}
                                                                    {rr.eventType === 'timepenalty' && <>
                                                                        <i class='fa-solid fa-timer text-warning' /><br />
                                                                        <small>
                                                                            <Text id='race.event-timepenalty'>timepenalty</Text>
                                                                        </small>
                                                                    </>}
                                                                </td>
                                                                <td>
                                                                    {rr.description && <>
                                                                        <Markdown markdown={rr.description} />
                                                                    </>}
                                                                </td>
                                                            </tr>
                                                        </>);
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </small>
                                </div>
                            </div>
                        </>}
                    </> : <></>}


                </div>
            </div>
        </>);
    }
}

export default RaceView;
