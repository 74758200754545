import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

const MARKDOWN_OPTIONS = {
    pedantic: false,
    gfm: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    smartypants: true,
    xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
    },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

const MONTH_NAMES = [
    'jan', 'feb', 'mar', 'apr', 'mai', 'jun',
    'jul', 'aug', 'sep', 'okt', 'nov', 'des',
];

const ACCOUNT_MAP = {
    '1200': 'Aktiverte kostn. gruppe d',
    '1201': 'Aktiverte kostn. gruppe a',
    '1202': 'Aktiverte kostn. leide lokaler',
    '1460': 'Lagerbeholdning salgsvarer',
    '1480': 'Forskudd til leverandører',
    '1510': 'Kunder',
    '1511': 'Avsetning for kundetap',
    '1525': 'Annen kundefordring',
    '1530': 'Annen kundefordring2',
    '1610': 'Fordring forsikring Agria',
    '1612': 'Fordring forsikring Codan',
    '1614': 'Fordring forsikring DnB NOR',
    '1616': 'Fordring forsikring Enter',
    '1618': 'Fordring forsikring Gjensidige',
    '1620': 'Fordring forsikring IF',
    '1622': 'Fordring forsikring Jernbanepersonalets',
    '1623': 'Fordring forsikring Nemi',
    '1624': 'Fordring forsikring Sparebank1',
    '1626': 'Fordring forsikring Storebrand',
    '1628': 'Fordring forsikring Tennant',
    '1630': 'Fordring forsikring Terra',
    '1632': 'Fordring forsikring Tryg Vesta',
    '1634': 'Fordring forsikring Frende',
    '1636': 'Fordring forsikring - annet selskap',
    '1910': 'Kasse',
    '1913': 'Bank kort (fordring)',
    '1915': 'Visa (fordring)',
    '1916': 'Eurocard (fordring)',
    '1917': 'Diners (fordring)',
    '1925': 'Fording på inkassoselskap kundeinnbetalinger',
    '1935': 'Bank plasseringskonto',
    '1939': 'Bank folio',
    '1940': 'Bank skattetrekk',
    '2000': 'Aksjekapital',
    '2020': 'Overkursfond',
    '2050': 'Egenkapital fri',
    '2114': 'Skyldig konsulent avg.fritt',
    '2410': 'Leverandører',
    '2412': 'Skyldig konsulent',
    '2500': 'Skyldig skatt utsatt',
    '2510': 'Skyldig skatt betalbar',
    '2610': 'Skyldig Skattetrekk',
    '2710': 'Utgående mva',
    '2720': 'Inngående mva',
    '2730': 'Investeringsavgift',
    '2750': 'Fylkesskattesjefen',
    '2780': 'Skyldig arbeidsgiveravgift',
    '2781': 'Skyldig arbeidsg.avg feriep',
    '2782': 'Gjeld bank',
    '2800': 'Skyldig utbytte',
    '2910': 'Skyldig lønn, feriepenger etc',
    '2920': 'Skyldig Feriepenger',
    '2930': 'Gjeld til ansatte og aksjonærer',
    '2960': 'Påløpne kostnader',
    '2965': 'Annen kortsiktig gjeld',
    '3001': 'Medisinsk utstyr',
    '3010': 'Poliklinikk',
    '3011': 'Fôrsalg',
    '3012': 'Rekvisitasalg',
    '3015': 'Varesalg avgiftsfritt',
    '3019': 'Ande salgsinntekter',
    '3030': 'Tenner',
    '3032': 'Røntgen/Ultralyd',
    '3034': 'Pelsstell',
    '3036': 'Vaksiner',
    '3038': 'Andre klinikkinntekter',
    '3040': 'Operasjon vanlig',
    '3042': 'Operasjon Spesialist',
    '3044': 'Lab eksternt',
    '3046': 'Lab internt',
    '3069': 'Fysio/kiro/rehab',
    '3620': 'Leieinntekter klinikk',
    '3900': 'Andre inntekter',
    '4006': 'Varekjøp - vaksiner',
    '4008': 'Varekjøp - medisin',
    '4010': 'Varekjøp - utstyr (vet) forbr.',
    '4012': 'Varekjøp - salgsrekvisita',
    '4013': 'Varekjøp - fôr',
    '4019': 'Varekjøp - annet',
    '4090': 'Beholdningsendring varer',
    '4110': 'Varekjøp - avgiftsfritt',
    '4510': 'Andre fremmede tjenester',
    '4511': 'Konsulent',
    '4512': 'Analyser, kremering',
    '5010': 'Lønn',
    '5130': 'Lønn u.feriep.gr.lag',
    '5190': 'Feriepenger',
    '5410': 'Arbeidsgiveravgift',
    '5411': 'Arbeidsgiveravgift på feriepenger',
    '5960': 'Sosiale kostnader',
    '5990': 'Andre godtgjørelser',
    '6010': 'Ordinære avskrivinger',
    '6290': 'Leiekostnader mva-fritt',
    '6300': 'Leiekostnader mva pliktig',
    '6340': 'Strøm',
    '6540': 'Utstyr, inventer (uaktivert)',
    '6700': 'Regnskap, revisjon mv',
    '6730': 'Inkassokostnader',
    '6800': 'Kontorrekvisita, trykksaker mv',
    '6840': 'Aviser og Tidsskrifter',
    '6850': 'Faglitteratur',
    '6860': 'Kurs (inkl. reise og diett)',
    '6900': 'Telefon',
    '6940': 'Porto',
    '7100': 'Bilgodtgjørelse',
    '7140': 'Andre reise og diettkostn.',
    '7320': 'Reklame Div',
    '7322': 'Reklame Telekatalog',
    '7323': 'Reklame Internett',
    '7410': 'Kontingenter og gaver',
    '7500': 'Forsikringer',
    '7600': 'Patentkostnader',
    '7790': 'Andre driftskostnader',
    '7830': 'Tap på fordringer',
    '7832': 'Ettergitt',
    '8050': 'Renteinntekter',
    '8150': 'Renteutgifter',
    '8190': 'Andre finanskostnader',
    '8192': 'Andre ekstraordinære kostnader',
    '8600': 'Skattekostnad betalbar',
    '8620': 'Skattekostnad utsatt',
    '8800': 'Årsoppgjør',
    '8920': 'Utbytte',
    '8931': 'Privatkonto uttak kontanter',
    '8939': 'Privatkonto uttak annet',
    '8950': 'Tilførte midler',
    '3013': 'Medisinering',
    '3050': 'Varer som takst',
    '1920': 'Vipps',
    '1950': 'Ansatt rabatt',
    '1700': 'Kreditt egenandel ansatte (lønn)',
};

function getReportDate(inputDate, reportType, onlyFromDate) {
    const reportDate = new Date(`${inputDate}T00:00:00`);
    // console.log({ inputDate, reportDate})
    // console.log({ reportType })
    const reportDay = reportDate.getDate();
    const reportMonth = reportDate.getMonth();
    const reportYear = reportDate.getFullYear();

    if (reportType === 'day') {
        return `${reportDay}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
    } else if (reportType === 'z-report') {
        return `${reportDay}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
    } else if (reportType === 'month') {
        return `${onlyFromDate ? reportDay : 1} - ${util.daysInMonth(reportMonth + 1, reportYear)}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
    } else if (reportType === 'year') {
        return `1.jan - 31.des ${reportYear}`;
    }
    return '';//`${reportDay}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
}

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class ReportsPrint extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

//     loadAll = async () => {
//         const { id } = this.props;
// console.log('reportPrint', { id })
//         const { saleReportStore } = this.props.stores;
//         await saleReportStore.load(id);
//     }

//     componentDidMount() {
//         this.loadAll();
//     }

    render() {
        const { week, isNew, drawerLevel, journals = [] } = this.props;
        const { sections } = this.state;
        const { customerStore, userStore, saleStore, saleReportStore } = this.props.stores;
        const { customer } = userStore;
        const { user, isAdmin } = userStore;

        const { saleReport = {} } = saleReportStore;

        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const {
            saleTotal = 0,
            saleTax = 0,
            newSale = {},
        } = saleStore;
        // const saleIsLocked = !!newSale.paymentMethod;
        // console.log({ newSale })

        const { animalStore, appState } = this.props.stores;
        const { animal, owners } = animalStore;
        const { view = {} } = appState;

        const {
            totalNumberOfSales,
            aggSaleTotal,
            aggSaleTotalNew,
            aggSaleTotalPaymentMethods,
            aggSaleTotalTax,
        } = saleStore;

        if (!saleReport) {
            return (<></>);
        }

        let totalForAccounts = 0;
        if (saleReport.saleAccounts) {
            totalForAccounts = saleReport.saleAccounts.map(account => account.price).reduce((a, b) => a + b, 0);
        }

        return (<>
            <div class='receipt'>
                <div class='d-flex flex-column receipt'>
                    <div class='d-flex flex-row justify-content-between'>
                        <div class='text-center d-flex align-items-center'>
                            {customer.images && customer.images.length > 0 && <img src={localUtil.displayImg(customer.images[0], this.props, '220x')} class='img-fluid' style='max-height: 100px;' />}
                        </div>
                        <div class='d-flex align-items-center'>
                            <h3 class='font-weight-lighter'>{customer.name}</h3>
                        </div>
                    </div>
                    <div class='d-flex flex-row justify-content-between mt-5'>
                        <div class='col-12 d-flex flex-column align-items-end'>
                            <div class='d-flex flex-row'><Text id='dyrejournal.report-date'>Report Date:</Text>
                            {saleReport.reportType === 'periode' ? <>
                                {util.isoDate(saleReport.reportDateTimeStart, true)} - {util.isoDate(saleReport.reportDateTimeEnd, true)}
                            </> : <>
                                {getReportDate(util.isoDate(saleReport.reportDate, false, false, true), saleReport.reportType)}
                            </>}
                            </div>
                            <div class='d-flex flex-row'><Text id='dyrejournal.responsible'>Responsible:</Text> {user.firstname} {user.lastname}</div>
                        </div>
                    </div>

                    <hr />

                    <div class='d-flex flex-column mt-3'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                {saleReport.reportType === 'day' && <>
                                    <Text id='dyrejournal.report.daily'>Daily</Text>
                                </>}
                                {saleReport.reportType === 'periode' && <>
                                    <Text id='dyrejournal.report.periode'>Periode</Text>
                                </>}
                                {saleReport.reportType === 'z-report' && <>
                                    <Text id='dyrejournal.report.z-report'>Z-Report</Text>
                                </>}
                                {saleReport.reportType === 'month' && <>
                                    <Text id='dyrejournal.report.monthly'>Monthly</Text>
                                </>}
                                {saleReport.reportType === 'year' && <>
                                    <Text id='dyrejournal.report.yearly'>Yearly</Text>
                                </>}
                            </div>
                            <div class={`d-flex flex-column py-3 box-container`}>
                                {(saleReport.reportType === 'day' || saleReport.reportType === 'periode') && <>
                                    <div class='table-'>
                                        <table class='table table-sm table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.cashregister'>Cashregister</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.bankcard'>Bankcard</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.vipps'>Vipps</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.invoice'>Invoice</Text></th>
                                                    {/* <th style='text-align: right;'><Text id='dyrejournal.report.account'>Account</Text></th> */}
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.creditNote'>creditNote</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.turnover'>turnover</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.discount'>Discount</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.tax'>Tax inc</Text></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.outgoing-last-report'>Outgoing last report</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    {/* <td style='text-align: right;'>0</td> */}
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>+ <Text id='dyrejournal.report.current-report'>Today</Text></td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCash, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCard, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountVipps, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountInvoice, 2)}</nobr>
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountAccount, 2)}</nobr>
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCreditNote, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTotal, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountDiscount, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTax, 2)}</nobr>
                                                    </td>
                                                </tr>
                                                <tr style='border-top: 1px solid #808080;'>
                                                    <td style='text-align: left;'>= <Text id='dyrejournal.report.outgoing-inventory'>Outgoing inventory</Text></td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCash, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCard, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountVipps, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountInvoice, 2)}</nobr>
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountAccount, 2)}</nobr>
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCreditNote, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTotal, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountDiscount, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTax, 2)}</nobr>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>+ <Text id='dyrejournal.report.added-change'>Added change</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>- <Text id='dyrejournal.report.bank-withdrawal'>Bank withdrawal</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>= <Text id='dyrejournal.report.outgoing-cash'>Outgoing cash</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.counted-cash'>Counted cash</Text></td>
                                                    <td style='text-align: right;'>{util.format(saleReport.countedCash, 2)}</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.deviation'>Deviation</Text></td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationCash, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationCard, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationVipps, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationInvoice, 2)}</td>
                                                    {/* <td style='text-align: right;'>{util.format(saleReport.deviationAccount, 2)}</td> */}
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationCreditNote, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationTotal, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationDiscount, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationTax, 2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>}
                                {saleReport.reportType === 'z-report' && <>
                                    <div class='table-'>
                                        <table class='table table-sm table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.cashregister'>Cashregister</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.bankcard'>Bankcard</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.vipps'>Vipps</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.invoice'>Invoice</Text></th>
                                                    {/* <th style='text-align: right;'><Text id='dyrejournal.report.account'>Account</Text></th> */}
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.creditNote'>creditNote</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.turnover'>turnover</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.discount'>Discount</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.tax'>Tax inc</Text></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.outgoing-last-report'>Outgoing last report</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    {/* <td style='text-align: right;'>0</td> */}
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>+ <Text id='dyrejournal.report.current-report'>Today</Text></td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCash, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCard, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountVipps, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountInvoice, 2)}</nobr>
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountAccount, 2)}</nobr>
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCreditNote, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTotal, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountDiscount, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTax, 2)}</nobr>
                                                    </td>
                                                </tr>
                                                <tr style='border-top: 1px solid #808080;'>
                                                    <td style='text-align: left;'>= <Text id='dyrejournal.report.outgoing-inventory'>Outgoing inventory</Text></td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCash, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCard, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountVipps, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountInvoice, 2)}</nobr>
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountAccount, 2)}</nobr>
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCreditNote, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTotal, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountDiscount, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTax, 2)}</nobr>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>+ <Text id='dyrejournal.report.added-change'>Added change</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>- <Text id='dyrejournal.report.bank-withdrawal'>Bank withdrawal</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>= <Text id='dyrejournal.report.outgoing-cash'>Outgoing cash</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.counted-cash'>Counted cash</Text></td>
                                                    <td style='text-align: right;'>{util.format(saleReport.countedCash, 2)}</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.deviation'>Deviation</Text></td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationCash, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationCard, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationVipps, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationInvoice, 2)}</td>
                                                    {/* <td style='text-align: right;'>{util.format(saleReport.deviationAccount, 2)}</td> */}
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationCreditNote, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationTotal, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationDiscount, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationTax, 2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>}

                                {(saleReport.reportType === 'month' || saleReport.reportType === 'year') && <>
                                    <div class='table-'>
                                        <table class='table table-sm table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.cashregister'>Cashregister</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.bankcard'>Bankcard</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.vipps'>Vipps</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.invoice'>Invoice</Text></th>
                                                    {/* <th style='text-align: right;'><Text id='dyrejournal.report.account'>Account</Text></th> */}
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.creditNote'>creditNote</Text></th>
                                                    {/* <th style='text-align: right;'><Text id='dyrejournal.report.unpaid'>unpaid</Text></th> */}
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.turnover'>turnover</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.discount'>Discount</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.tax'>Tax inc</Text></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><Text id='dyrejournal.report.totalsales'>Total sales</Text></td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCash, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCard, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountVipps, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountInvoice, 2)}</nobr>
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountAccount, 2)}</nobr>
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountCreditNote, 2)}</nobr>
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountUnpaid, 2)}</nobr>
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTotal, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountDiscount, 2)}</nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>{util.format(saleReport.amountTax, 2)}</nobr>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.deviation'>Deviation</Text></td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationCash, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationCard, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationVipps, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationInvoice, 2)}</td>
                                                    {/* <td style='text-align: right;'>{util.format(saleReport.deviationAccount, 2)}</td> */}
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationCreditNote, 2)}</td>
                                                    {/* <td style='text-align: right;'>{util.format(saleReport.deviationUnpaid, 2)}</td> */}
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationTotal, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationDiscount, 2)}</td>
                                                    <td style='text-align: right;'>{util.format(saleReport.deviationTax, 2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>}

                                {/* <div class='table-responsive w-50'>
                                    <table class='table table-sm table-hover table-striped'>
                                        <tbody>
                                            <tr>
                                                <th><Text id='dyrejournal.report.total-sales'>Total sales</Text></th>
                                                <td style='text-align: right;'>{totalNumberOfSales}</td>
                                            </tr>
                                            <tr>
                                                <th><Text id='dyrejournal.report.total-amount-wo-tax'>Total amount wo/tax</Text></th>
                                                <td style='text-align: right;'>{util.format(saleStore.lineTotal(aggSaleTotal, true, 1), 2)}</td>
                                            </tr>
                                            <tr>
                                                <th><Text id='dyrejournal.report.total-tax'>Total tax</Text></th>
                                                <td style='text-align: right;'>{util.format(aggSaleTotal.tax, 2)}</td>
                                            </tr>
                                            <tr>
                                                <th><Text id='dyrejournal.report.total-number-of-products-services'>Total number of products/services</Text></th>
                                                <td style='text-align: right;'>{aggSaleTotal.count}</td>
                                            </tr>
                                            {aggSaleTotalPaymentMethods && aggSaleTotalPaymentMethods.map((item, idx) => {
                                                return (<>
                                                    <tr>
                                                        <th><Text id='dyrejournal.report.total-amount-wo-tax'>Total amount wo/tax for {item.paymentMethod}</Text></th>
                                                        <td style='text-align: right;'>{util.format(item.total, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th><Text id='dyrejournal.report.total-tax'>Total tax for {item.paymentMethod}</Text></th>
                                                        <td style='text-align: right;'>{util.format(item.tax, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th><Text id='dyrejournal.report.total-number-of-products-services'>Total number of products/services for {item.paymentMethod}</Text></th>
                                                        <td style='text-align: right;'>{item.count}</td>
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div> */}

                                {/* Report for today<br />
                                Total number of sales: {totalNumberOfSales}<br />
                                Total amount wo/tax: {util.format(aggSaleTotal.total, 2)}<br />
                                Total tax: {util.format(aggSaleTotal.tax, 2)}<br />
                                Total number of products/services: {aggSaleTotal.count}<br />

                                {aggSaleTotalPaymentMethods && aggSaleTotalPaymentMethods.map((item, idx) => {
                                    return (<>
                                        Total amount wo/tax for {item.paymentMethod}: {util.format(item.total, 2)}<br />
                                        Total tax for {item.paymentMethod}: {util.format(item.tax, 2)}<br />
                                        Total number of products/services for {item.paymentMethod}: {item.count}<br />
                                    </>);
                                })} */}
                                {/* Total appointments: 0<br />
                                Total journals: 0<br />
                                Total prescriptions: 0<br />
                                Total animals: 0<br /> */}

                                {/* <xmp>{JSON.stringify(aggSaleTotal, null, 2)}</xmp>
                                <xmp>{JSON.stringify(aggSaleTotalPaymentMethods, null, 2)}</xmp>
                                <xmp>{JSON.stringify(aggSaleTotalTax, null, 2)}</xmp> */}
                            </div>
                        </div>
                        <div class='w-100 d-flex flex-column'>
                            {saleReport.comment && <div class='d-flex flex-column'>
                                <div class='font-weight-lighter box-header d-flex flex-row justify-content-between'>
                                    <Text id='dyrejournal.report.comment'>Comment</Text>
                                </div>
                                <div class=''>
                                    <Markdown markdown={saleReport.comment} markedOpts={MARKDOWN_OPTIONS} />
                                </div>
                            </div>}
                        </div>
                    </div>


                    {saleReport.salesPersons && saleReport.salesPersons.length > 0 && <>
                    {/* <xmp>{JSON.stringify(saleReport.salePersonsAccounts, null, 2)}</xmp> */}
                        <div class='d-flex flex-column mt-4'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.user-sales'>Sales by user</Text>
                            </div>
                            <div class={`d-flex flex-column py-3 box-container`}>
                                <div class='table-'>
                                    <table class='table table-sm table-hover table-striped'>
                                        <thead>
                                            <tr>
                                                <th><Text id='dyrejournal.report.user'>User</Text></th>
                                                <th style='text-align: right;'><Text id='dyrejournal.report.sales'>Sales</Text></th>
                                                {saleReport.salePersonsAccounts && saleReport.salePersonsAccounts.sort().map(account => {
                                                    return (<>
                                                        <th style='text-align: right;'>{ACCOUNT_MAP[account] || account || 'Ingen konto'}</th>
                                                    </>);
                                                })}
                                                <th style='text-align: right;'><Text id='dyrejournal.report.sum'>Sum</Text></th>
                                                {/* <th style='text-align: right;'><Text id='dyrejournal.report.tax'>Tax inc</Text></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {saleReport.salesPersons && saleReport.salesPersons.map((userSale, idx) => {
                                                // console.log({ userSale });
                                                if (!userSale.user) {
                                                    return <></>;
                                                }
                                                return (<>
                                                    <tr>
                                                        <td>{userSale.user.firstname} {userSale.user.lastname}</td>
                                                        <td style='text-align: right;'>{util.format(userSale.count, 0)}</td>
                                                        {saleReport.salePersonsAccounts && saleReport.salePersonsAccounts.map(account => {
                                                            const accountSale = userSale.accounts?.find(item => item.account === account);
                                                            return (<>
                                                                <td style='text-align: right;'>
                                                                    <nobr>
                                                                        {accountSale ? <>
                                                                            {util.format(accountSale.price, 2)}
                                                                        </> : <></>}
                                                                    </nobr>
                                                                </td>
                                                            </>);
                                                        })}
                                                        <td style='text-align: right;'><nobr>{util.format(userSale.price, 2)}</nobr></td>
                                                        {/* <td style='text-align: right;'><nobr>{util.format(saleStore.calculateTax(userSale.price), 2)}</nobr></td> */}
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class='w-100 d-flex flex-row justify-content-center'>
                                <small class='text-muted'>
                                    <Text id='dyrejournal.report.user-sales-help'>Sales by user. The sum is the total sale for this user without rounding price to nearest NOK. Tax is NOT included in the sum.</Text>
                                </small>
                            </div>
                        </div>
                    </>}

                    <div class='d-flex flex-column mt-4'>
                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                            <Text id='dyrejournal.report.account-sales'>Sales by account</Text>
                        </div>
                        <div class={`d-flex flex-column py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <div class='table-responsive w-50'>

                                <table class='table table-sm table-hover table-striped' style='font-size: 0.9em; line-height: 1.0em;'>
                                    <thead>
                                        <tr>
                                            <th><Text id='dyrejournal.report.account'>Account</Text></th>
                                            <th style='text-align: right;'><Text id='dyrejournal.report.sum'>Sum</Text></th>
                                            {/* <th style='text-align: right;'><Text id='dyrejournal.report.tax'>Tax inc</Text></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {saleReport.saleAccounts && saleReport.saleAccounts.map(account => <>
                                            <tr>
                                                <td style='text-align: left;'>
                                                    <nobr>
                                                        {ACCOUNT_MAP[account.account] || account.account || 'Ingen konto'}
                                                    </nobr>
                                                </td>
                                                <td style='text-align: right;'>
                                                    <nobr>
                                                        {util.format(account.price, 2)}
                                                    </nobr>
                                                </td>
                                                {/* <td style='text-align: right;'>
                                                    <nobr>
                                                        {util.format(saleStore.calculateTax(account.price), 2)}
                                                    </nobr>
                                                </td> */}
                                            </tr>
                                        </>)}
                                        <tr>
                                            <td class='font-weight-bold'><Text id='dyrejournal.report.total-ex-tax'>Total without tax</Text></td>
                                            <td class='font-weight-bold' style='text-align: right;'>{util.format(totalForAccounts, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td class='font-weight-bold'><Text id='dyrejournal.report.total-inc-tax'>Total with tax</Text></td>
                                            <td class='font-weight-bold' style='text-align: right;'>{util.format(totalForAccounts * 1.25, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class='w-100 d-flex flex-row justify-content-center'>
                            <small class='text-muted'>
                                <Text id='dyrejournal.report.account-sales-help'>Sales by account. The sum is the total sale for this account without rounding price to nearest NOK. Tax is NOT included in the sum.</Text>
                            </small>
                        </div>
                    </div>


                    <div class='d-flex flex-row text text-muted mt-4'>
                        <Text id='dyrejournal.print-date'>Print Date:</Text> {util.formatDate(new Date(), { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                    </div>
                    <hr class='mt-1' />

                    <div class='d-flex flex-row justify-content-between mt-3'>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.business-register'>The Business Register</Text></div>
                                <div>{customer.orgNumber}</div>
                                <div>{customer.name}</div>
                                {customer.orgNumber2 && <div>{customer.orgNumber}</div>}
                                {customer.name2 && <div>{customer.name}</div>}
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.address'>Address</Text></div>
                                <div>{customer.address}</div>
                                <div>{customer.postalcode} {customer.place}</div>
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.phone-fax'>Phone/Fax</Text></div>
                                <div>{customer.phone}</div>
                                <div>{customer.fax}</div>
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.internett'>Internet</Text></div>
                                <div>{customer.url}</div>
                                <div>{customer.email}</div>
                            </small>
                        </div>
                    </div>

                </div>

            </div>
        </>);
    }
}

export default ReportsPrint;
