import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';
import ImageScroller from '../../components/imagescroller';
import MiniGraph from '../../components/lineGraph/mini';
import FileList from '../../components/fileViewer/list';
import UrlList from '../../components/urlViewer/list';
import GradientRight from '../../components/gui/gradientRight';
import HelpText from '../../components/gui/helpText';
import StarRating from '../../components/gui/starRating';
import Input from '../../components/form/input';

const MARKDOWN_OPTIONS = {
	pedantic: false,
	gfm: true,
	breaks: true,
	sanitize: false,
	smartLists: true,
	smartypants: true,
	xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

function productImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}


@withText(props => ({
    installationListHelpText: <Text id='product.installation-list-help'>List of all the places where this product is currently located.</Text>,
    productUsageListHelpText: <Text id='product.usage-list-help'>All logged usage of this product. This can be service of a car, boat trips, shooting practice, etc.</Text>,
    productLoanListHelpText: <Text id='product.loan-list-help'>All loan outs of this product. You can see if it's still out or if it has been returned. You can also see if you have sent any reminders.</Text>,
    titleChildrenProductsText: <Text id='product.children-list-title'>Connected products</Text>,
    titleParentProductsText: <Text id='product.product-list-title'>Parent product</Text>,
}))
@observer
class Product extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, race, customer, skipUpdate, callback = () => {} } = props;
        const { productStore } = this.props.stores;

        if (emptyFirst && !skipUpdate) {
            productStore.updateKeyValue('product', {});
        }
        // await productStore.load(id, false, { query:{ installationId: installation.id }, addData: ['installations'] });
        await productStore.load(id, false, {
            query: {
                id,
                customer,
            },
            addData: [],
            skipUpdate,
            url: `/api/products/public/`,
        });
        callback();
    }

    editProduct = () => {
		const { id, drawerLevel = 1, skipUpdate } = this.props;
		const { appState } = this.props.stores;

		const { drawerHeightMedium } = appState;
		appState.openDrawer('editProduct', {
			height: drawerHeightMedium,
			id,
            skipUpdate,
		}, drawerLevel + 1);
	}

    addToCart = async () => {
        const { race } = this.props;
        const { productStore, userStore } = this.props.stores;
        const { product } = productStore;
        const { user } = userStore;
        if (!user.id) {
            return;
        }
        await userStore.addToCart({
            product: product.id,
            qty: 1,
            race,
        });
        await userStore.getInfo({ forceLoad: true });
    }

    gotoCart = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer();
        route('/cart');
    }

    continueShopping = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawer();
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            drawerLevel = 1,
        } = this.props;
        const { userStore, productStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { cart = [] } = user;
        const { product = {} } = productStore;

        const hasProductInBasket = cart.find(item => item.product === product.id);

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex px-3'>
                                <ImageScroller images={product.images} stores={this.props.stores} showImg={img => productImg(img, this.props, '1920x')} maxHeight={'50vh'} />
                            </div>
                            <div class={`d-flex flex-row mt-3 mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                {/* <div
                                    class='box-line-qty text-right'
                                    onClick={this.editProductQty}
                                    data-id={product.id}
                                    data-qty={product.qty}
                                >
                                    {product.qty}
                                </div> */}
                                <div class={`flex-fill d-flex flex-column px-3 py-3 text-overflow position-relative`}>
                                    <GradientRight stores={this.props.stores} styles={'border-radius: 0 1.0rem 1.0rem 0 !important;'} />

                                    {product.name && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.name'>Name</Text>
                                        </span>
                                        <span class='ml-2'>{product.name}</span>
                                    </div>}

                                    {['productNumber', 'description', 'currency', 'price', 'inStock'].map(key => {
                                        if (!product[key]) {
                                            return '';
                                        }
                                        const field = fields.getField('product', key);
                                        const displayValue = field.displayPublicValue || (value => value);
                                        return (<>
                                            <div class='d-flex flex-row'>
                                                <span class='font-weight-lighter text-muted'>
                                                    {field.title}
                                                </span>
                                                {field.type === 'textarea' ? <>
                                                    <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                        <Markdown markdown={displayValue(product[key])} markedOpts={MARKDOWN_OPTIONS} />
                                                    </div>
                                                </> : <>
                                                    <span class='ml-2'>
                                                        {displayValue(product[key], product)}
                                                    </span>
                                                </>}
                                            </div>
                                        </>);
                                    })}

                                </div>
                            </div>

                            {user.id ? <>
                                <div class={`flex-fill d-flex flex-row px-3 mt-3 justify-content-center`}>
                                    {hasProductInBasket ? <>
                                        <button
                                            class={`btn  btn-success rounded-pill mx-1`}
                                            onClick={this.gotoCart}
                                        >
                                            <i class='fa-duotone fa-cart-shopping' /> <Text id='product.goto-cart'>Goto cart</Text>
                                        </button>
                                        <button
                                            class={`btn btn-secondary rounded-pill mx-1`}
                                            onClick={this.continueShopping}
                                        >
                                            <i class='fa-duotone fa-cart-plus' /> <Text id='product.continue-shopping'>Continue shopping</Text>
                                        </button>
                                    </> : <>
                                        {product.inStock > 0 ? <>
                                            <button
                                                class={`btn btn-block btn-primary rounded-pill`}
                                                onClick={this.addToCart}
                                            >
                                                <i class='fa-duotone fa-cart-plus' /> <Text id='product.add-to-cart'>Add to cart</Text>
                                            </button>
                                        </> : <>
                                            <button
                                                class={`btn btn-block btn-danger rounded-pill`}
                                                disabled
                                            >
                                                <i class='fa-duotone fa-cart-plus' /> <Text id='product.out-of-stock'>Out of stock</Text>
                                            </button>
                                        </>}
                                    </>}
                                </div>
                            </> : <>
                                <div class={`flex-fill d-flex flex-row justify-content-center px-3 mt-3`}>
                                    <Text id='user.login-to-add-to-cart'>You must login to be able to add products to your cart.</Text>
                                </div>
                                <div class={`flex-fill d-flex flex-row justify-content-center px-3 mt-3`}>
                                    <button class='btn btn-lg btn-block btn-primary rounded-pill' onClick={() => route('/login')}>
                                        <i class='fa-duotone fa-right-to-bracket mr-2' /> <Text id='user.goto-login'>Goto Login</Text>
                                    </button>
                                </div>
                            </>}


                            {/* hasProductInBasket: {hasProductInBasket ? 'true' : 'false'} */}


                                {/*
                                    TODO: New features:
                                    - Show history for product
                                    - Show loan status for product
                                    - Track usage of product: Hours, distance, position, etc.
                                */}



                        </div>
                    </div>


                </div>
            </div>
        </>);
    }
}

export default Product;
