import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class CommentStore extends LocalModel {
    constructor() {
        super('comment', {
            namePlural: 'comments',
            namePluralReal: 'comments',
            sort: 'createdDate',
            limit: 500,
            api: {
                search: {
                    url: '/api/comments/',
                    params: {
                        limit: 50,
                        sort: 'lastname',
                    },
                },
                load: {
                    url: '/api/comments/',
                    params: {},
                },
                save: {
                    url: '/api/comments/',
                    params: {},
                },
                delete: {
                    url: '/api/comments/',
                    params: {},
                },
            },
        });
    }

    @observable showAddComment = {};

    @observable newComment = {};

    @observable comment = {};

    @observable comments = [];

}

const store = new CommentStore();
export default store;

// Named export for the class itself
export { CommentStore };
