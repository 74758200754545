import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

function solveEquation(mainGap, plankWidth, planks) {
    // console.log('y', y);
    // This solves the equation: (constant + x) * y + x = 100.5
    // const target = mainGap; // Right-hand side of the equation

    // Rearrange the equation:
    // (plankWidth + x) * y + x = mainGap
    // plankWidth * y + y * x + x = mainGap
    // y * x + x = mainGap - plankWidth * y
    // x * (y + 1) = mainGap - plankWidth * y
    // x = (mainGap - plankWidth * y) / (y + 1)

    // Check for division by zero
    if (planks + 1 === 0) {
        throw new Error("Invalid input: y + 1 must not be zero.");
    }

    const numerator = mainGap - plankWidth * planks;
    const denominator = planks + 1;

    const x = numerator / denominator;

    return x;
}

function getFactor(planks) {
    if (planks >= 28) {
        return 8;
    } else if (planks >= 25) {
        return 7;
    } else if (planks >= 20) {
        return 6;
    } else if (planks >= 10) {
        return 5;
    } else if (planks >= 8) {
        return 4;
    } else if (planks >= 4) {
        return 3;
    } else if (planks >= 2) {
        return 2;
    } else {
        return 2; // Default case for planks <= 2
    }
}

class PanelCalculatorStore extends LocalModel {
    constructor() {
        super('panelCalculator', {
            namePlural: 'panelCalculators',
            namePluralReal: 'panelCalculators',
            sort: 'name',
            limit: 100,
            api: {
                search: {
                    url: '/api/panelCalculators/',
                    params: {
                        limit: 15,
                        sort: 'name',
                    },
                },
                load: {
                    url: '/api/panelCalculators/',
                    params: {},
                },
                save: {
                    url: '/api/panelCalculators/',
                    params: {},
                },
                delete: {
                    url: '/api/panelCalculators/',
                    params: {},
                },
            },
        });
    }

    @observable panelCalculation = {
        plankWidth: 149,
        mainGap: 1500,
        gap: 0,
        planks: 0,
        gaps: 0,
        totalWidth: 0,
        factor: 2,
    };


    @action
    calculate() {
        const { panelCalculation } = this;
        const { plankWidth, mainGap, planks } = panelCalculation;

        if (mainGap < plankWidth) {
            return;
        }

        let calculatedPlanks = Math.floor(mainGap / plankWidth) - 1; // Number of planks
        let solution = 0;
        // Gap must be wider than half the width of a plank
        if (!planks) {
            let totalPlanks = calculatedPlanks;
            while (solution < (plankWidth / 2)) {
                totalPlanks -= 1;
                solution = solveEquation(mainGap, plankWidth, totalPlanks);
            }
            calculatedPlanks = totalPlanks;
        } else {
            solution = solveEquation(mainGap, plankWidth, planks);
        }
        // const solution = solveEquation(mainGap, plankWidth, planks || calculatedPlanks);
        // console.log('solution', solution);
        panelCalculation.gap = Math.round(solution);
        panelCalculation.planks = planks || calculatedPlanks;
        panelCalculation.gaps = panelCalculation.planks + 1;
        panelCalculation.totalWidth = panelCalculation.planks * plankWidth + panelCalculation.gaps * panelCalculation.gap;
        panelCalculation.overlap = (panelCalculation.plankWidth - panelCalculation.gap) / 2;
        // Factor should be scaled to the width of the screen with all planks and gaps.
        panelCalculation.factor = getFactor(panelCalculation.planks);
    console.log('factor', panelCalculation.factor);

    }

}

const store = new PanelCalculatorStore();
export default store;

// Named export for the class itself
export { PanelCalculatorStore };
