import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

const MARKDOWN_OPTIONS = {
    pedantic: false,
    gfm: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    smartypants: true,
    xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
    },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

const MONTH_NAMES = [
    'jan', 'feb', 'mar', 'apr', 'mai', 'jun',
    'jul', 'aug', 'sep', 'okt', 'nov', 'des',
];

const ACCOUNT_MAP = {
    '1200': 'Aktiverte kostn. gruppe d',
    '1201': 'Aktiverte kostn. gruppe a',
    '1202': 'Aktiverte kostn. leide lokaler',
    '1460': 'Lagerbeholdning salgsvarer',
    '1480': 'Forskudd til leverandører',
    '1510': 'Kunder',
    '1511': 'Avsetning for kundetap',
    '1525': 'Annen kundefordring',
    '1530': 'Annen kundefordring2',
    '1610': 'Fordring forsikring Agria',
    '1612': 'Fordring forsikring Codan',
    '1614': 'Fordring forsikring DnB NOR',
    '1616': 'Fordring forsikring Enter',
    '1618': 'Fordring forsikring Gjensidige',
    '1620': 'Fordring forsikring IF',
    '1622': 'Fordring forsikring Jernbanepersonalets',
    '1623': 'Fordring forsikring Nemi',
    '1624': 'Fordring forsikring Sparebank1',
    '1626': 'Fordring forsikring Storebrand',
    '1628': 'Fordring forsikring Tennant',
    '1630': 'Fordring forsikring Terra',
    '1632': 'Fordring forsikring Tryg Vesta',
    '1634': 'Fordring forsikring Frende',
    '1636': 'Fordring forsikring - annet selskap',
    '1910': 'Kasse',
    '1913': 'Bank kort (fordring)',
    '1915': 'Visa (fordring)',
    '1916': 'Eurocard (fordring)',
    '1917': 'Diners (fordring)',
    '1925': 'Fording på inkassoselskap kundeinnbetalinger',
    '1935': 'Bank plasseringskonto',
    '1939': 'Bank folio',
    '1940': 'Bank skattetrekk',
    '2000': 'Aksjekapital',
    '2020': 'Overkursfond',
    '2050': 'Egenkapital fri',
    '2114': 'Skyldig konsulent avg.fritt',
    '2410': 'Leverandører',
    '2412': 'Skyldig konsulent',
    '2500': 'Skyldig skatt utsatt',
    '2510': 'Skyldig skatt betalbar',
    '2610': 'Skyldig Skattetrekk',
    '2710': 'Utgående mva',
    '2720': 'Inngående mva',
    '2730': 'Investeringsavgift',
    '2750': 'Fylkesskattesjefen',
    '2780': 'Skyldig arbeidsgiveravgift',
    '2781': 'Skyldig arbeidsg.avg feriep',
    '2782': 'Gjeld bank',
    '2800': 'Skyldig utbytte',
    '2910': 'Skyldig lønn, feriepenger etc',
    '2920': 'Skyldig Feriepenger',
    '2930': 'Gjeld til ansatte og aksjonærer',
    '2960': 'Påløpne kostnader',
    '2965': 'Annen kortsiktig gjeld',
    '3001': 'Medisinsk utstyr',
    '3010': 'Poliklinikk',
    '3011': 'Fôrsalg',
    '3012': 'Rekvisitasalg',
    '3015': 'Varesalg avgiftsfritt',
    '3019': 'Ande salgsinntekter',
    '3030': 'Tenner',
    '3032': 'Røntgen/Ultralyd',
    '3034': 'Pelsstell',
    '3036': 'Vaksiner',
    '3038': 'Andre klinikkinntekter',
    '3040': 'Operasjon vanlig',
    '3042': 'Operasjon Spesialist',
    '3044': 'Lab eksternt',
    '3046': 'Lab internt',
    '3069': 'Fysio/kiro/rehab',
    '3620': 'Leieinntekter klinikk',
    '3900': 'Andre inntekter',
    '4006': 'Varekjøp - vaksiner',
    '4008': 'Varekjøp - medisin',
    '4010': 'Varekjøp - utstyr (vet) forbr.',
    '4012': 'Varekjøp - salgsrekvisita',
    '4013': 'Varekjøp - fôr',
    '4019': 'Varekjøp - annet',
    '4090': 'Beholdningsendring varer',
    '4110': 'Varekjøp - avgiftsfritt',
    '4510': 'Andre fremmede tjenester',
    '4511': 'Konsulent',
    '4512': 'Analyser, kremering',
    '5010': 'Lønn',
    '5130': 'Lønn u.feriep.gr.lag',
    '5190': 'Feriepenger',
    '5410': 'Arbeidsgiveravgift',
    '5411': 'Arbeidsgiveravgift på feriepenger',
    '5960': 'Sosiale kostnader',
    '5990': 'Andre godtgjørelser',
    '6010': 'Ordinære avskrivinger',
    '6290': 'Leiekostnader mva-fritt',
    '6300': 'Leiekostnader mva pliktig',
    '6340': 'Strøm',
    '6540': 'Utstyr, inventer (uaktivert)',
    '6700': 'Regnskap, revisjon mv',
    '6730': 'Inkassokostnader',
    '6800': 'Kontorrekvisita, trykksaker mv',
    '6840': 'Aviser og Tidsskrifter',
    '6850': 'Faglitteratur',
    '6860': 'Kurs (inkl. reise og diett)',
    '6900': 'Telefon',
    '6940': 'Porto',
    '7100': 'Bilgodtgjørelse',
    '7140': 'Andre reise og diettkostn.',
    '7320': 'Reklame Div',
    '7322': 'Reklame Telekatalog',
    '7323': 'Reklame Internett',
    '7410': 'Kontingenter og gaver',
    '7500': 'Forsikringer',
    '7600': 'Patentkostnader',
    '7790': 'Andre driftskostnader',
    '7830': 'Tap på fordringer',
    '7832': 'Ettergitt',
    '8050': 'Renteinntekter',
    '8150': 'Renteutgifter',
    '8190': 'Andre finanskostnader',
    '8192': 'Andre ekstraordinære kostnader',
    '8600': 'Skattekostnad betalbar',
    '8620': 'Skattekostnad utsatt',
    '8800': 'Årsoppgjør',
    '8920': 'Utbytte',
    '8931': 'Privatkonto uttak kontanter',
    '8939': 'Privatkonto uttak annet',
    '8950': 'Tilførte midler',
    '3013': 'Medisinering',
    '3050': 'Varer som takst',
    '1920': 'Vipps',
    '1950': 'Ansatt rabatt',
    '1700': 'Kreditt egenandel ansatte (lønn)',
};

function getReportDate(inputDate, reportType, onlyFromDate) {
    const reportDate = new Date(`${inputDate}T00:00:00`);
    // console.log({ inputDate, reportDate})
    // console.log({ reportType })
    const reportDay = reportDate.getDate();
    const reportMonth = reportDate.getMonth();
    const reportYear = reportDate.getFullYear();

    if (reportType === 'day') {
        return `${reportDay}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
    } else if (reportType === 'z-report') {
        return `${reportDay}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
    } else if (reportType === 'month') {
        return `${onlyFromDate ? reportDay : 1} - ${util.daysInMonth(reportMonth + 1, reportYear)}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
    } else if (reportType === 'year') {
        return `1.jan - 31.des ${reportYear}`;
    }
    return '';//`${reportDay}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
}

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class ReportsPrint extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

//     loadAll = async () => {
//         const { id } = this.props;
// console.log('reportPrint', { id })
//         const { saleReportStore } = this.props.stores;
//         await saleReportStore.load(id);
//     }

//     componentDidMount() {
//         this.loadAll();
//     }

    render() {
        const { userStore, saleStore } = this.props.stores;
        const { customer } = userStore;
        const { user, isAdmin } = userStore;

        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const {
            reportUserDetails = [],
        } = saleStore;

        let totalByDate = {};
        let total = {
            fee: 0,
            medicine: 0,
            equipment: 0,
            other: 0,
            price: 0,
            totalBeforeTax: 0,
            vat: 0,
            total: 0,
        };
        let currentDate = '';

        return (<>
            <div class='receipt'>
                <div class='d-flex flex-column receipt'>
                    <div class='d-flex flex-row justify-content-between'>
                        <div class='text-center d-flex align-items-center'>
                            {customer.images && customer.images.length > 0 && <img src={localUtil.displayImg(customer.images[0], this.props, '220x')} class='img-fluid' style='max-height: 100px;' />}
                        </div>
                        <div class='d-flex align-items-center'>
                            <h3 class='font-weight-lighter'>{customer.name}</h3>
                        </div>
                    </div>
                    <div class='d-flex flex-row justify-content-between mt-5'>
                        <div class='col-12 d-flex flex-column align-items-end'>
                            {/* <div class='d-flex flex-row'><Text id='dyrejournal.report-date'>Report Date:</Text> {getReportDate(util.isoDate(saleReport.reportDate, false, false, true), saleReport.reportType)}</div> */}
                            {/* <div class='d-flex flex-row'><Text id='dyrejournal.responsible'>Responsible:</Text> {user.firstname} {user.lastname}</div> */}
                        </div>
                    </div>

                    <hr />

                    <div class='d-flex flex-column mt-3'>
                        <div class='w-100 d-flex flex-column'>
                            {/* <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                {saleReport.reportType === 'day' && <>
                                    <Text id='dyrejournal.report.daily'>Daily</Text>
                                </>}
                                {saleReport.reportType === 'z-report' && <>
                                    <Text id='dyrejournal.report.z-report'>Z-Report</Text>
                                </>}
                                {saleReport.reportType === 'month' && <>
                                    <Text id='dyrejournal.report.monthly'>Monthly</Text>
                                </>}
                                {saleReport.reportType === 'year' && <>
                                    <Text id='dyrejournal.report.yearly'>Yearly</Text>
                                </>}
                            </div> */}
                            <div class={`d-flex flex-column py-3 box-container`}>
                                <div class='table-responsive'>
                                    <table class='table table-sm table-hover table-striped'>
                                        <thead>
                                            <tr>
                                                <th><Text id='dyrejournal.report.salenumber'>Sale number</Text></th>
                                                <th><Text id='dyrejournal.report.saledate'>Sale date</Text></th>
                                                <th><Text id='dyrejournal.report.customer'>Customer</Text></th>
                                                <th><Text id='dyrejournal.report.fee'>Fee</Text></th>
                                                <th><Text id='dyrejournal.report.medicine'>medicine</Text></th>
                                                <th><Text id='dyrejournal.report.equipment'>equipment</Text></th>
                                                <th><Text id='dyrejournal.report.other'>other</Text></th>
                                                <th><Text id='dyrejournal.report.price'>price</Text></th>
                                                <th><Text id='dyrejournal.report.totalBeforeTax'>totalBeforeTax</Text></th>
                                                <th><Text id='dyrejournal.report.vat'>vat</Text></th>
                                                <th><Text id='dyrejournal.report.total'>total</Text></th>
                                                <th><Text id='dyrejournal.report.paymentMethod'>paymentMethod</Text></th>
                                                <th><Text id='dyrejournal.report.employeeName'>employeeName</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportUserDetails.map((line, index) => {
                                                const date = util.isoDate(line.saleDate, false, false, true);
                                                const isSameDate = currentDate === date;

                                                let totalForPreviousDay;
                                                if (!isSameDate && currentDate) {
                                                    totalForPreviousDay = totalByDate[currentDate];
                                                }
                                                currentDate = date;
                                                if (!totalByDate[date]) {
                                                    totalByDate[date] = {
                                                        fee: 0,
                                                        medicine: 0,
                                                        equipment: 0,
                                                        other: 0,
                                                        price: 0,
                                                        totalBeforeTax: 0,
                                                        vat: 0,
                                                        total: 0,
                                                    };
                                                }
                                                totalByDate[date].fee += line.fee;
                                                totalByDate[date].medicine += line.medicine;
                                                totalByDate[date].equipment += line.equipment;
                                                totalByDate[date].other += line.other;
                                                totalByDate[date].price += line.price;
                                                totalByDate[date].totalBeforeTax += line.totalBeforeTax;
                                                totalByDate[date].vat += line.vat;
                                                totalByDate[date].total += line.total;

                                                total.fee += line.fee;
                                                total.medicine += line.medicine;
                                                total.equipment += line.equipment;
                                                total.other += line.other;
                                                total.price += line.price;
                                                total.totalBeforeTax += line.totalBeforeTax;
                                                total.vat += line.vat;
                                                total.total += line.total;

                                                return (<>

                                                    {!isSameDate && totalForPreviousDay && <tr>
                                                        <td class='font-weight-bold' colSpan='3'><Text id='dyrejournal.report.total'>Total</Text></td>
                                                        <td class='font-weight-bold text-right'><nobr>{util.format(totalForPreviousDay.fee, 2)}</nobr></td>
                                                        <td class='font-weight-bold text-right'><nobr>{util.format(totalForPreviousDay.medicine, 2)}</nobr></td>
                                                        <td class='font-weight-bold text-right'><nobr>{util.format(totalForPreviousDay.equipment, 2)}</nobr></td>
                                                        <td class='font-weight-bold text-right'><nobr>{util.format(totalForPreviousDay.other, 2)}</nobr></td>
                                                        <td class='font-weight-bold text-right'><nobr>{util.format(totalForPreviousDay.price, 2)}</nobr></td>
                                                        <td class='font-weight-bold text-right'><nobr>{util.format(totalForPreviousDay.totalBeforeTax, 2)}</nobr></td>
                                                        <td class='font-weight-bold text-right'><nobr>{util.format(totalForPreviousDay.vat, 2)}</nobr></td>
                                                        <td class='font-weight-bold text-right'><nobr>{util.format(totalForPreviousDay.total, 2)}</nobr></td>
                                                        <td class='font-weight-bold' colSpan='2'></td>
                                                    </tr>}

                                                    {!isSameDate && <tr>
                                                        <td colspan='13' class='font-weight-bolder bg-primary-light'>
                                                            {util.formatDate(line.saleDate, { locale: 'nb-NO' })}
                                                        </td>
                                                    </tr>}

                                                    <tr>
                                                        <td>{line.saleNumber}</td>
                                                        <td>{util.formatDate(line.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</td>
                                                        <td>{line.customerName}</td>
                                                        <td class='text-right'><nobr>{util.format(line.fee, 2)}</nobr></td>
                                                        <td class='text-right'><nobr>{util.format(line.medicine, 2)}</nobr></td>
                                                        <td class='text-right'><nobr>{util.format(line.equipment, 2)}</nobr></td>
                                                        <td class='text-right'><nobr>{util.format(line.other, 2)}</nobr></td>
                                                        <td class='text-right'><nobr>{util.format(line.price, 2)}</nobr></td>
                                                        <td class='text-right'><nobr>{util.format(line.totalBeforeTax, 2)}</nobr></td>
                                                        <td class='text-right'><nobr>{util.format(line.vat, 2)}</nobr></td>
                                                        <td class='text-right'><nobr>{util.format(line.total, 2)}</nobr></td>
                                                        <td>{line.paymentMethod}</td>
                                                        <td>{line.employeeName}</td>
                                                    </tr>

                                                    {/* <tr>
                                                        <td colSpan='13'>
                                                            {date}
                                                        </td>
                                                    </tr> */}
                                                </>);
                                            })}
                                            <tr>
                                                <td class='font-weight-bolder bg-success-light' colSpan='3'><Text id='dyrejournal.report.total'>Total</Text></td>
                                                <td class='font-weight-bolder bg-success-light text-right'><nobr>{util.format(total.fee, 2)}</nobr></td>
                                                <td class='font-weight-bolder bg-success-light text-right'><nobr>{util.format(total.medicine, 2)}</nobr></td>
                                                <td class='font-weight-bolder bg-success-light text-right'><nobr>{util.format(total.equipment, 2)}</nobr></td>
                                                <td class='font-weight-bolder bg-success-light text-right'><nobr>{util.format(total.other, 2)}</nobr></td>
                                                <td class='font-weight-bolder bg-success-light text-right'><nobr>{util.format(total.price, 2)}</nobr></td>
                                                <td class='font-weight-bolder bg-success-light text-right'><nobr>{util.format(total.totalBeforeTax, 2)}</nobr></td>
                                                <td class='font-weight-bolder bg-success-light text-right'><nobr>{util.format(total.vat, 2)}</nobr></td>
                                                <td class='font-weight-bolder bg-success-light text-right'><nobr>{util.format(total.total, 2)}</nobr></td>
                                                <td class='font-weight-bolder bg-success-light text-right' colSpan='2'></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class='d-flex flex-row text text-muted mt-4'>
                        <Text id='dyrejournal.print-date'>Print Date:</Text> {util.formatDate(new Date(), { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                    </div>
                    <hr class='mt-1' />

                    <div class='d-flex flex-row justify-content-between mt-3'>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.business-register'>The Business Register</Text></div>
                                <div>{customer.orgNumber}</div>
                                <div>{customer.name}</div>
                                {customer.orgNumber2 && <div>{customer.orgNumber}</div>}
                                {customer.name2 && <div>{customer.name}</div>}
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.address'>Address</Text></div>
                                <div>{customer.address}</div>
                                <div>{customer.postalcode} {customer.place}</div>
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.phone-fax'>Phone/Fax</Text></div>
                                <div>{customer.phone}</div>
                                <div>{customer.fax}</div>
                            </small>
                        </div>
                        <div class='d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.internett'>Internet</Text></div>
                                <div>{customer.url}</div>
                                <div>{customer.email}</div>
                            </small>
                        </div>
                    </div>

                </div>

            </div>
        </>);
    }
}

export default ReportsPrint;
