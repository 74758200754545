import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class DiagnoseStore extends LocalModel {
    constructor() {
        super('diagnose', {
            namePlural: 'diagnoses',
            sort: 'diagnoseCode',
            limit: 1000,
            api: {
                search: {
                    url: '/api/diagnoses/',
                    params: {
                        limit: 25,
                        sort: 'diagnoseCode',
                    },
                },
                load: {
                    url: '/api/diagnoses/',
                    params: {},
                },
                save: {
                    url: '/api/diagnoses/',
                    params: {},
                },
                delete: {
                    url: '/api/diagnoses/',
                    params: {},
                },
            },
        });
    }

    @observable newDiagnose = {};

    @observable diagnose = {};

    @observable diagnoses = [];
}

const store = new DiagnoseStore();
export default store;
