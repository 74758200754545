import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';
import Placeholder from '../../components/gui/placeholder';
import MarkdownDisplay from '../../components/gui/markdownDisplay';
import VideoStreamingPlayer from '../../routes/videostreaming/player';

import localUtil from '../../lib/util';
import { has } from 'mobx';

@observer
class Likes extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            hasLiked: false,
            likes: null,
        };
    }

    loadAll = async (props = this.props) => {
        const { likes = [] } = this.props;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const userHasLiked = likes.find(like => like.user === user.id);
        if (userHasLiked) {
            this.setState({ hasLiked: true });
        }
    }

    addLike = async () => {
        const { type, objectId } = this.props;
        const { likeStore } = this.props.stores;
        await likeStore.insert({ type, objectId });
        this.setState({ hasLiked: true });

        const likes = await likeStore.load({
            query: { type, objectId },
            skipUpdate: true,
        });
        this.setState({ likes });
    }

    removeLike = async () => {
        const { type, objectId } = this.props;
        const { likeStore } = this.props.stores;
        await likeStore.delete(1, 'id', { type, objectId });
        this.setState({ hasLiked: false });

        const likes = await likeStore.load({
            query: { type, objectId },
            skipUpdate: true,
        });
        this.setState({ likes });
    }

    toggleComment = async () => {
        const { commentStore } = this.props.stores;
        const { showAddComment = {} } = commentStore;
        const { type, objectId } = this.props;

        const addComment = { ...showAddComment };
        addComment[`${type}-${objectId}`] = !addComment[`${type}-${objectId}`];
        commentStore.updateKeyValue('showAddComment', addComment);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { hasLiked, likes: localLikes } = this.state;
        const {
            likes = [],
            type,
            objectId,
        } = this.props;
        const { appState, userStore, commentStore } = this.props.stores;
        const { user } = userStore;
        const isLoggedIn = user.id;

        const { showAddComment } = commentStore;

        const finalLikes = localLikes || likes;

        return (<>
            <div class='w-100 d-flex flex-row justify-content-between position-relative text-muted px-3 py-2'>
                <div class='d-flex flex-row ' style='font-size: 1.5rem;'>
                    {isLoggedIn ? <>
                        <i
                            class={`fa-${hasLiked ? 'solid' : 'light'} fa-thumbs-up`}
                            onClick={hasLiked ? this.removeLike : this.addLike}
                        ></i>
                        <i
                            class={`fa-${showAddComment[`${type}-${objectId}`] ? 'solid' : 'light'} fa-comment ml-2`}
                            onClick={this.toggleComment}
                        ></i>
                    </> : <>
                        <small style='font-size: 0.8rem;'>
                            <Text id='like.loginToLike'>Login to like</Text>
                        </small>
                    </>}
                </div>
                <div class='d-flex flex-row font-weight-light'>
                    {finalLikes.length} <Text id='like.likes'>Likes</Text>
                </div>
            </div>
        </>);
    }
}

export default Likes;
