import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';


@withText(props => ({
    visitorTitle: <Text id='dyrejournal.visitor.search-title'>Search for visitor</Text>,
}))
@observer
class SearchVisitor extends Component {
	constructor(props) {
        super(props);
        this.state = {
            visitorAnimals: [],
        };
    }

    loadAnimals = async visitorIds => {
        const { animalStore, saleStore } = this.props.stores;
        if (visitorIds.length > 0) {
            const response = await animalStore.load({
                query: {
                    owner: visitorIds,
                },
                skipUpdate: true,
            });
            this.setState({ visitorAnimals: response });
        } else {
            this.setState({ visitorAnimals: [] });
        }
    }

    searchVisitorCallback = (visitor) => {
        console.log('searchVisitorCallback', visitor);
        if (visitor && visitor.id) {
            this.loadAnimals([visitor.id]);
        }
    }

    onClickChooseAnimal = e => {
        const { idx, id, name } = e.target.closest('.resultLine').dataset;
        const { visitorAnimals } = this.state;

        // const animal = visitorAnimals[idx];
        // console.log('onClickChooseAnimal', { idx, id, name });

        const idInt = parseInt(id);
        const animal = visitorAnimals.find(e => e.id === idInt);
        // console.log('onClickChooseAnimal', animal);

        const { calendarEventStore } = this.props.stores;
        // const { newCalendarEvent } = calendarEventStore;
        // const { animals } = newCalendarEvent;
        calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'animals', [{
            id: animal.id,
            name: animal.name,
            breed: animal.breed,
            species: animal.species,
            birth: animal.birth,
            chipId: animal.chipId,
        }]);
        calendarEventStore.updateObjectKeyValue('newCalendarEvent', 'title', `${animal.name} (${animal.species})`);
    }

    render() {
        const { visitorTitle } = this.props;
        const { visitorStore } = this.props.stores;
        const { visitorAnimals } = this.state;

        return(<>
            <div class=''>
                <DyrejournalSaleSearch
                    stores={this.props.stores}
                    key={'visitor'}
                    title={visitorTitle}
                    holdingStore={visitorStore}
                    searchResultList={'visitorResults'}
                    searchResultsLine={(e, idx, selectedIndex) => {
                        return(<>
                            <div class={`d-flex flex-column px-3 py-1 ${idx === selectedIndex ? 'selected' : ''}`}>
                                <div class='d-flex flex-row justify-content-between'>
                                    <div>{localUtil.showName(e)}</div>
                                    <div class='ml-2'>{e?.cellphone}</div>
                                </div>
                                <div class='d-flex flex-row justify-content-between'>
                                    <small class='font-weight-lighter'>{e?.address} {e?.postalcode} {e?.place}</small>
                                </div>
                            </div>
                        </>);
                    }}
                    callback={this.searchVisitorCallback}
                    skipSaleUpdate={true}
                    // rightButton={'hasVisitor && newSaleTItle'}
                    // rightButtonClassNames='btn-success'
                    // rightButtonClick={this.onClickNewSale}
                    ref={c => this.visitorSearchRef = c}
                />
            </div>
            <div>
                {(visitorAnimals && visitorAnimals.length > 0) && <>
                    <div class='mt-3'>
                        <div class='font-weight-lighter text-muted'>
                            <small class='text-uppercase'>
                                <Text id='dyrejournal.animal.owned-by-visitor'>Animals owned by visitor</Text>
                            </small>
                        </div>
                        <div class='d-flex flex-wrap overflow-auto' style='max-height: 200px;'>
                            {visitorAnimals.filter(e => !e.isDececed && !e.isRelocated).map((e, idx) => <>
                                <small
                                    class={`${e.isDececed ? 'bg-warning' : e.isRelocated ? 'bg-info' : 'bg-light'} resultLine d-flex justify-content-between mr-2 mt-1 px-2 font-weight-normal rounded-lg`}
                                    style='cursor: pointer; width: 32%;'
                                    onClick={this.onClickChooseAnimal}
                                    data-idx={idx}
                                    data-id={e.id}
                                    data-name={e.name}
                                >
                                    {e.isDececed && <i class='fa-duotone fa-tombstone mr-1' />}
                                    {e.isRelocated && <i class='fa-duotone fa-arrow-up-right-from-square mr-1' />}
                                    <div class='flex-fill'>{e.name} ({e.species})</div>
                                    <div class='ml-2 text-right'><nobr>{e.chipId && <><i class='fa-solid fa-microchip text-muted mr-2' /></>} {e.birth && util.age(e.birth)}</nobr></div>

                                </small>
                            </>)}
                        </div>
                    </div>
                </>}
            </div>
        </>);
    }
}

export default SearchVisitor;