import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';

import ImageScroller from '../../components/imagescroller';
import Placeholder from '../../components/gui/placeholder';
import MarkdownDisplay from '../../components/gui/markdownDisplay';
import VideoStreamingPlayer from '../../routes/videostreaming/player';

import localUtil from '../../lib/util';

@observer
class Comments extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            body: '',
            comments: null,
        };
    }

    loadAll = async (props = this.props) => {
        const { comments = [] } = this.props;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const userHascommented = comments.find(comment => comment.user === user.id);
        if (userHascommented) {
            this.setState({ hascommented: true });
        }
    }

    onInput = e => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ body: e.target.value });
    }

    onKeyDown = e => {
        e.stopPropagation();
        // e.preventDefault();
    }

    addComment = async () => {
        const { body } = this.state;
        const { type, objectId } = this.props;
        const { commentStore } = this.props.stores;
        await commentStore.insert({ type, objectId, body });
        this.setState({ hascommented: true, body: '' });

        const comments = await commentStore.load({
            query: { type, objectId },
            skipUpdate: true,
        });
        this.setState({ comments });
        this.toggleComment();
    }

    removeComment = async () => {
        const { type, objectId } = this.props;
        const { commentStore } = this.props.stores;
        await commentStore.delete(1, 'id', { type, objectId });

        const comments = await commentStore.load({
            query: { type, objectId },
            skipUpdate: true,
        });
        this.setState({ comments });
    }

    editComment = async () => {
        const { body } = this.state;
        const { type, objectId } = this.props;
        const { commentStore } = this.props.stores;
        const updateObject = {
            id: 1,
            type,
            objectId,
            body: this.state.body,
        };
        await commentStore.update(updateObject);

        const comments = await commentStore.load({
            query: { type, objectId },
            skipUpdate: true,
        });
        this.setState({ comments });
    }

    toggleComment = async () => {
        const { commentStore } = this.props.stores;
        const { showAddComment = {} } = commentStore;
        const { type, objectId } = this.props;

        const addComment = { ...showAddComment };
        addComment[`${type}-${objectId}`] = !addComment[`${type}-${objectId}`];
        commentStore.updateKeyValue('showAddComment', addComment);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { comments: localComments, body } = this.state;
        const {
            comments = [],
            type,
            objectId,
        } = this.props;
        const { appState, userStore, commentStore } = this.props.stores;
        const { user } = userStore;
        const isLoggedIn = user.id;

        const { showAddComment } = commentStore;

        const finalComments = localComments || comments;

        return (<>
            <div class='w-100 d-flex flex-column justify-content-between position-relative text-muted px-3 py-2'>

                {/* <xmp>{JSON.stringify(finalComments, null, 2)}</xmp> */}

                {finalComments.map(comment => {
                    const isOwnComment = comment.user === user.id;

                    return (<>
                    <div class='d-flex flex-row w-100 justify-content-between'>
                        <div
                            class={`commentBubble ${isOwnComment ? 'myComment' : 'othersComment'}`}
                            key={comment.id}  // recommended if you have a unique id
                        >
                            <div class='commentBody'>
                                <MarkdownDisplay markdown={comment.body || '&nbsp;'} />
                            </div>
                        </div>
                    </div>
                    <div class={`d-flex flex-row w-100 justify-content-${isOwnComment ? 'end' : 'start'} mb-2`}>
                        <small class='font-weight-bold'>{comment.creator}</small>
                        <small class='text-muted px-2'>
                            •
                        </small>
                        <small class=''>{localUtil.getTimeAgo(comment.createdDate)}</small>
                        <small class='text-muted px-2'>
                            •
                        </small>
                        {isOwnComment ? <>
                                <small
                                    class='text-muted'
                                    onClick={this.removeComment}
                                >
                                    <i class='fa-duotone fa-trash'></i>
                                </small>
                                {/* <button
                                    class='btn btn-link btn-sm text-muted p-0'
                                    onClick={this.editComment}
                                >
                                    <i class='fa-duotone
                                    fa-edit'></i>
                                </button> */}
                        </> : <>
                            <small
                                class='text-muted'
                                onClick={this.toggleComment}
                            >
                                <Text id='comment.reply'>Reply</Text>
                            </small>
                        </>}
                    </div>

                    </>);
                })}

                {showAddComment[`${type}-${objectId}`] ? <>
                    <div class='d-flex flex-row'>
                        <textarea
                            class='form-control'
                            placeholder='Comment'
                            onInput={this.onInput}
                            rows={1}
                            onKeyDown={this.onKeyDown}
                            onKeyUp={localUtil.resizeTextarea}
                            value={body}
                        ></textarea>
                        <button
                            class='btn btn-primary'
                            onClick={this.addComment}
                        >
                            <i class="fa-duotone fa-paper-plane"></i>
                        </button>
                    </div>
                </> : <></>}

            </div>
        </>);
    }
}

export default Comments;
