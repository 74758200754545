import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';

configure({ enforceActions: 'always' });

class VisitorStore extends LocalModel {
    constructor() {
        super('visitor', {
            namePlural: 'visitors',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/visitors/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/visitors/',
                    params: {},
                },
                save: {
                    url: '/api/visitors/',
                    params: {},
                },
                delete: {
                    url: '/api/visitors/',
                    params: {},
                },
            },
        });
    }

    @observable newVisitor = {};

    @observable visitor = {};

    @observable visitors = [];

    @observable currentVisitors = [];

    @observable searchResult = [];

    @observable visitorAnimals = [];

    @observable visitorProducts = [];

    @observable visitorCalendars = [];

    @observable visitorHours = [];

    @observable animalSpecies = [];

    @observable animalBreeds = [];

    @observable calendarEvent = {};

    @observable calendarEventStatus = null;

    @observable calendarEventMessage = null;

    @observable jwtToken = util.get('visitorJwt') || 'null';

    @observable sendLoginCodeStatus = null;

    @observable sendLoginCodeMessage = null;

    async searchVisitors({ search }) {
        const response = await util.fetchApi(`/api/visitors/`, { publish: true, method: 'GET' }, { search });
        console.log('searchVisitors', response);
        switch (response.status) {
            case 200:
                this.updateKeyValue('searchResult', response.data);
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async mergeVisitors({ currentVisitor, mergeWith }) {
        console.log('mergeVisitors', currentVisitor, mergeWith);
        const response = await util.fetchApi(`/api/visitors/mergeVisitors`, { publish: true, method: 'PATCH' }, {
            currentVisitor,
            mergeWith,
        });
        console.log('mergeVisitors', response);
        switch (response.status) {
            case 200:
                return response;
            case 401:
                break;
        }
    }

    async loadVisitorFromUser() {
        const response = await util.fetchApi(`/api/visitors/loadFromUser`, { publish: true, method: 'GET' }, {});
        return response?.data?.visitor;
    }

    async getInfo(props = {}) {
        const {
            customerId,
        } = props;

        const infoResponse = await util.fetchApi(`/api/visitors/info/${customerId}`, {
            jwtToken: this.jwtToken || 'null',
            method: 'GET',
        }, {});
        switch (infoResponse.status) {
            case 200:
                this.updateKeyValue('visitor', infoResponse.data);
                break;
            case 401:
                break;
            default:
                break;
        }
    }

    async sendLoginCodeSms({ cellphone, customer, captchaToken }) {
        const loginResponse = await util.fetchApi('/api/visitors/sendcode', {
            method: 'POST',
        }, {
            cellphone,
            customer,
            captchaToken,
        });
        this.updateKeyValue('sendLoginCodeStatus', loginResponse.status);
        switch (loginResponse.status) {
            case 200:
                this.updateKeyValue('sendLoginCodeMessage', 'Code sent to your phone.');
                return true;
            case 400:
                this.updateKeyValue('sendLoginCodeMessage', 'Captcha verification failed.');
                return false;
            case 500:
                this.updateKeyValue('sendLoginCodeMessage', 'Captcha verification error.');
                return false;
            default:
                return false;
        }
    }

    logout() {
        this.updateKeyValue('visitor', {});
        this.updateKeyValue('jwtToken', 'null');
        localStorage.removeItem('visitorJwt');
        localStorage.removeItem('visitorCellphone');
    }

    async validateLoginCodePhone(cellphone, code, language = 'en') {
        const isValidCode = util.validateCode(code, 1000, 999999);
        if (isValidCode) {
            const loginResponse = await util.fetchApi('/api/visitors/login/code/phone', {
                method: 'POST',
            }, { cellphone, code });
            switch (loginResponse.status) {
                case 200:
                    this.updateKeyValue('visitor', loginResponse.data.visitor);
                    localStorage.setItem('visitorJwt', loginResponse.data.apiToken);
                    localStorage.setItem('visitorCellphone', loginResponse.data.visitor.cellphone);
                    this.updateKeyValue('jwtToken', loginResponse.data.apiToken);
                    return true;
                    break;
                case 403:
                    if (loginResponse.message === 'Wrong code!') {
                        this.updateObjectKeyValue('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectKeyValue('login', 'error', `Feil code! Husk at du må bruke den nyeste e-posten med koder.`);
                        } else {
                            this.updateObjectKeyValue('login', 'error', `Wrong code! Remember to use the latest email.`);
                        }
                    } else if (loginResponse.message === 'Login code has expired!') {
                        this.updateObjectKeyValue('loginLink', 'emailSent', false);
                        this.updateObjectKeyValue('login', 'errorIcon', 'fas fa-exclamation-triangle');
                        if (language === 'no') {
                            this.updateObjectKeyValue('login', 'error', `Koden har gått ut på dato. Be om en ny og forsøk igjen.`);
                        } else {
                            this.updateObjectKeyValue('login', 'error', `Code expired. Try to get a new code and try again.`);
                        }
                    } else {
                        this.updateObjectKeyValue('login', 'errorIcon', 'fas fa-bomb');
                        if (language === 'no') {
                            this.updateObjectKeyValue('login', 'error', `Det er noe feil med koden din. Du kan forsøke å be om en ny og prøve igjen.`);
                        } else {
                            this.updateObjectKeyValue('login', 'error', `Something wrong with your code! Try to get a new code and try again.`);
                        }
                    }
                    return false;
                    break;
                case 422:
                    this.updateObjectKeyValue('login', 'errorIcon', 'fas fa-exclamation-triangle');
                    if (language === 'no') {
                        this.updateObjectKeyValue('login', 'error', `Innloggingen feilet! Mangler info i noen av feltene under.`);
                    } else {
                        this.updateObjectKeyValue('login', 'error', `Login failed! Missing information.`);
                    }
                    return false;
                    break;
                default:
                    this.updateObjectKeyValue('login', 'errorIcon', 'fas fa-bomb');
                    if (language === 'no') {
                        this.updateObjectKeyValue('login', 'error', `Innloggingen feilet! ${loginResponse.status}: ${loginResponse.message}`);
                    } else {
                        this.updateObjectKeyValue('login', 'error', `Login failed! ${loginResponse.status}: ${loginResponse.message}`);
                    }
                    return false;
                    break;
            }
        }
    }

    async loadAnimals() {
        if (!this.visitor.id) {
            return;
        }
        const response = await util.fetchApi(`/api/visitors/animals`, {
            jwtToken: this.jwtToken || 'null',
            method: 'GET',
        }, {
            customer: this.visitor.customer,
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('visitorAnimals', response.data);
                if (response.included?.animalSpecies) {
                    this.updateKeyValue('animalSpecies', response.included.animalSpecies);
                }
                if (response.included?.animalBreeds) {
                    this.updateKeyValue('animalBreeds', response.included.animalBreeds);
                }
                break;
            default:
                break;
        }
    }

    async loadProducts() {
        if (!this.visitor.id) {
            return;
        }
        const response = await util.fetchApi(`/api/visitors/products`, {
            jwtToken: this.jwtToken || 'null',
            method: 'GET',
        }, {
            customer: this.visitor.customer,
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('visitorProducts', response.data);
                break;
            default:
                break;
        }
    }

    async loadCalendars() {
        if (!this.visitor.id) {
            return;
        }
        const response = await util.fetchApi(`/api/visitors/calendars`, {
            jwtToken: this.jwtToken || 'null',
            method: 'GET',
        }, {
            customer: this.visitor.customer,
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('visitorCalendars', response.data);
                break;
            default:
                break;
        }
    }

    async loadHours({ product, calendar }) {
        if (!this.visitor.id) {
            return;
        }
        const response = await util.fetchApi(`/api/visitors/hours`, {
            jwtToken: this.jwtToken || 'null',
            method: 'GET',
        }, {
            customer: this.visitor.customer,
            product,
            calendar,
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('visitorHours', response.data);
                break;
            default:
                break;
        }
    }

    async placeBooking({ visitor, animal, calendar, product, hour }) {
        if (!this.visitor.id) {
            return;
        }
        const response = await util.fetchApi(`/api/visitors/booking/place`, {
            jwtToken: this.jwtToken || 'null',
            method: 'POST',
        }, {
            customer: this.visitor.customer,
            visitor,
            animal,
            product,
            calendar,
            hour,
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('calendarEvent', response.data);
                this.updateKeyValue('calendarEventStatus', response.status);
                break;
            case 409:
                this.updateKeyValue('calendarEventMessage', response.message);
                this.updateKeyValue('calendarEventStatus', response.status);
            default:
                break;
        }
    }
}

const store = new VisitorStore();
export default store;
