import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';

const LIMIT = 10;

function conventionImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

function participantExists(participants, key, value) {
    return participants.some(participant => participant[key] === value);
}

function memberExists(members, key, value) {
    return members.some(member => member[key] === value);
}

function generateArray(X) {
    const myNewShinyArray = [...Array(parseInt(X, 10) + 1).keys()];
    myNewShinyArray.shift();
    return myNewShinyArray;
}

@observer
class CompetitionResult extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.get('competitionResult'),
        };
        this.mainContainer = null;
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, currentUserOnly } = props;
        const { competitionResultStore, userStore } = this.props.stores;
        const { user = {} } = userStore;
        await competitionResultStore.load({ query: {
            user: user.id,
        } });
    }

    newCompetitionResult = () => {
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMediumLarge } = appState;
		appState.openDrawer('newCompetitionResult', {
			height: drawerHeightMediumLarge,
            callback: user => {
                this.loadAll();
            },
		}, drawerLevel + 1);
    }

    editCompetitionResult = e => {
        const { id } = e.target.closest('tr').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMediumLarge } = appState;
		appState.openDrawer('editCompetitionResult', {
			height: drawerHeightMediumLarge,
            id,
            callback: user => {
                this.loadAll();
            },
		}, drawerLevel + 1);
    }

    setRefreshPage = () => {
        const { competitionResultStore } = this.props.stores;
        competitionResultStore.setRefreshPage(() => {
            // console.log('refresh page competitionResult list', this.props);
            this.loadAll(this.props, false, false);
        });
    }

    clearRefreshPage = () => {
        const { competitionResultStore } = this.props.stores;
        competitionResultStore.setRefreshPage(null);
    }

    componentDidMount() {
        this.loadAll();
        this.setRefreshPage();
    }

    componentWillUnmount() {
        this.clearRefreshPage();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const { showAll, offset = 0 } = this.state;
        const {
            classNames = '',
            title = 'Stevner / treninger',
            children,
            todayOnly,
        } = this.props;

        const { appState, userStore, competitionResultStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { competitionResults = [] } = competitionResultStore;

        const apiServer = util.getApiServer();
        const jwtToken = util.getJwtToken();

        const totalResults = competitionResults.length;
        const numIntervals = Math.ceil(totalResults / LIMIT);
        const intervals = Array.from({ length: numIntervals }, (_, index) => index * LIMIT);

        return (<>

            <div class='d-flex flex-row justify-content-center align-items-center mb-3'>
                <button
                    class='btn btn-primary rounded-pill mt-3 mx-1'
                    onClick={this.newCompetitionResult}
                >
                    <i class='fa-duotone fa-plus' /> <Text id='user.addCompetitionResult'>Add competition result</Text>
                </button>
                <a
                    class='btn btn-success text-white rounded-pill mt-3 mx-1'
                    href={`${apiServer}/api/competitionresults/download?jwtToken=${jwtToken}&offset=${offset}`}
                    target='_blank'
                >
                    <i class='fa-duotone fa-download' /> <Text id='user.download-police-form'>Download police form</Text>
                </a>
                {competitionResults.length > LIMIT && <>
                    <div class='mt-3 ml-2'>
                        <select
                            class={`form-control rounded-lg`}
                            onInput={linkstate(this, 'offset')}
                        >
                            <option value=''>-- Velg start --</option>
                            {intervals.map(start => (
                                <option value={start} key={start}>
                                    {start} - {Math.min(start + LIMIT, totalResults)}
                                </option>
                            ))}
                        </select>
                    </div>
                </>}
            </div>
            <div class='table-reponsive w-100'>
                <table class='table table-sm table-striped'>
                    <thead>
                        <tr>
                            <th class='text-muted w-5'><nobr><Text id='input.date'>date</Text></nobr></th>
                            <th class='text-muted'><nobr><Text id='input.title'>title</Text></nobr></th>
                            <th class='text-muted w-5'><nobr><Text id='input.competitionId'>competitionId</Text></nobr></th>
                            <th class='text-muted w-5'><nobr><Text id='input.organizer'>organizer</Text></nobr></th>
                            <th class='text-muted w-5'><nobr><Text id='input.place'>place</Text></nobr></th>
                            <th class='text-muted w-5'><nobr><Text id='input.program'>program</Text></nobr></th>
                            <th class='text-muted w-5'><nobr><Text id='input.class'>class</Text></nobr></th>
                            <th class='text-muted w-5'><nobr><Text id='input.rank'>rank</Text></nobr></th>
                            <th class='text-muted w-5'><nobr><Text id='input.score'>score</Text></nobr></th>
                            <th class='text-muted w-5'><nobr><Text id='input.promotionClass'>promotionClass</Text></nobr></th>
                        </tr>
                    </thead>
                    <tbody>
                        {competitionResults.map(competition => {
                            return (<>
                                <tr
                                    data-id={competition.id}
                                    onClick={this.editCompetitionResult}
                                >
                                    <td>
                                        <nobr>
                                            {util.formatDate(competition.date, { locale: 'nb-NO' })}
                                        </nobr>
                                    </td>
                                    <td>
                                        {competition.title}
                                    </td>
                                    <td>
                                        {competition.competitionId}
                                    </td>
                                    <td>
                                        {competition.organizer}
                                    </td>
                                    <td>
                                        {competition.place}
                                    </td>
                                    <td>
                                        {competition.program}
                                    </td>
                                    <td class='text-center'>
                                        {competition.class}
                                    </td>
                                    <td class='text-center'>
                                        {competition.rank}
                                    </td>
                                    <td class='text-center'>
                                        {competition.score}
                                    </td>
                                    <td class='text-center'>
                                        {competition.promotionClass}
                                    </td>
                                </tr>
                            </>);
                        })}

                    </tbody>
                </table>
            </div>
        </>);
    }
}

export default CompetitionResult;
