import { h, Component } from 'preact';
import util from 'preact-util';
import { route } from 'preact-router';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ConventionList from '../../components/convention/list';
import CompetitionResultlList from '../../components/competitionresult/list';
import ArticleList from '../../components/article/list';
import Camera from '../../components/gui/camera';
import DyrejournalSaleReceipt from '../../components/dyrejournal/saleReceipt';

@observer
class UserOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    loadAll = async () => {
        const { saleStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { id } = this.props;
        const idInt = parseInt(id);
        await saleStore.load(idInt, false, { query: {
            user: user.id,
            allCustomers: true,
        }});

        const { sale } = saleStore;
        saleStore.updateKeyValue('newSale', sale);
    }

    viewSale = e => {
        const { id } = e.target.closest('tr').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightLarge } = appState;
        // console.log('viewProduct', { id });
        appState.openDrawer('viewUserOrder', {
            id,
            height: drawerHeightLarge,
        }, drawerLevel + 1);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { userStore, saleStore } = this.props.stores;
        const { user } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { sales } = saleStore;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 0px; padding-bottom: 100px;'>
                    <div class='w-100 d-flex flex-column'>
                        <div class={`d-flex flex-column mx-3 mt-5 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                            <DyrejournalSaleReceipt stores={this.props.stores} />
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default UserOrders;

