import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';

import Markdown from 'preact-markdown';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import DyrejournalVisitorListMini from '../../components/dyrejournal/listVisitorMini';
import DyrejournalSaleReceipt from '../../components/dyrejournal/saleReceipt';
import DyrejournalSalePacklist from '../../components/dyrejournal/salePacklist';

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class SaleShipping extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    markAsPacked = async e => {
        this.setState({ paymentInProgress: true });
        const { callback = () => {} } = this.props;
        const { saleStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { newSale } = saleStore;

        saleStore.updateObjectKeyValue('newSale', 'packedDate', new Date());
        saleStore.updateObjectKeyValue('newSale', 'packedBy', user.id);
        //     updateField(id, field, value, findBy = 'id') {
        saleStore.updateField(newSale.id, 'packedDate', new Date());
        saleStore.updateField(newSale.id, 'packedBy', user.id);

        await saleStore.saveSale({ calledFrom: 'saleShipping.markAsPacked', event: e, fieldsToSave: ['packedDate', 'packedBy'] });
        this.setState({ paymentInProgress: false });
    }

    markAsShipped = async e => {
        this.setState({ paymentInProgress: true });
        const { callback = () => {} } = this.props;
        const { saleStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { newSale } = saleStore;

        saleStore.updateObjectKeyValue('newSale', 'shippingDate', new Date());
        saleStore.updateObjectKeyValue('newSale', 'shippedBy', user.id);

        saleStore.updateField(newSale.id, 'shippingDate', new Date());
        saleStore.updateField(newSale.id, 'shippedBy', user.id);

        await saleStore.saveSale({ calledFrom: 'saleShipping.markAsShipped', event: e, fieldsToSave: ['shippingDate', 'shippedBy'] });
        this.setState({ paymentInProgress: false });
    }

    saveTrackingNumber = async e => {
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;
        saleStore.updateObjectKeyValue('newSale', 'trackingNumber', e.target.value);
        saleStore.updateField(newSale.id, 'trackingNumber', e.target.value);

        clearTimeout(this.saveTrackingNumberTimer);
        this.saveTrackingNumberTimer = setTimeout(() => {
            saleStore.saveSale({ calledFrom: 'saleShipping.saveTrackingNumber', event: e, fieldsToSave: ['trackingNumber'] });
        }, 1000);
    }

    saveShippingMethod = async e => {
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;
        saleStore.updateObjectKeyValue('newSale', 'shippingMethod', e.target.value);
        saleStore.updateField(newSale.id, 'shippingMethod', e.target.value);

        clearTimeout(this.saveShippingMethodTimer);
        this.saveShippingMethodTimer = setTimeout(() => {
            saleStore.saveSale({ calledFrom: 'saleShipping.saveShippingMethod', event: e, fieldsToSave: ['shippingMethod'] });
        }, 1000);
    }

    onClickPrintPacklist = e => {
        // console.log('onClickPrintSale', e);
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'no' } = user;
        const languageDef = countryMap[language || 'default'];

        // Render Receipt component with data
        const packlist = <IntlProvider definition={languageDef}>
            <DyrejournalSalePacklist stores={this.props.stores} />
        </IntlProvider>;

        // Append to document
        const printDiv = document.createElement('div');
        printDiv.className = 'packlist';
        document.body.appendChild(printDiv);
        render(packlist, printDiv);
        // Trigger print dialog
        window.print();
        // Remove the appended div
        document.body.removeChild(printDiv);
        // Re-render the packlist component to reset it
        render(null, printDiv);
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const { sections, partialPayment, payDate, paymentInProgress } = this.state;
        const { userStore, saleStore } = this.props.stores;
        const { customer, user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const {
            saleTotal = 0,
            saleTax = 0,
            newSale = {},
            currentPayments = [],
        } = saleStore;

        const saleIsLocked = !!newSale.paymentMethod;
        const restPayment = saleStore.sumRestPayments(currentPayments, saleStore.sumTotal(newSale.products, false), false, false);

        const { featureFlags = {} } = customer;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        <Text id='dyrejournal.sale-shipping'>Sale Shipping</Text>
                    </div>
                    <div class='bg-light d-flex flex-column justify-content-start pt-3'>
                        {!newSale.id ? <>
                            <div class='w-100 d-flex flex-row justify-content-center mt-3'>
                                <div class='spinner-grow text-secondary' role='status'>
                                    <span class='sr-only'>Loading...</span>
                                </div>
                            </div>
                        </> : <>
                            <div class='w-100 d-flex flex-column justify-content-center mt-3'>
                                {/*
                                    Display the visitor details.
                                */}
                                {/* <DyrejournalVisitorListMini
                                    classNames='mx-1'
                                    stores={this.props.stores}
                                    visitors={newSale.visitors}
                                    // saleIsLocked={saleIsLocked}
                                    drawerLevel={drawerLevel}
                                />
 */}

                                {/*
                                    Show a status bar at the top with the steps below.
                                    Also show buttons for marking as packed and shipped.

                                    1. Button for marking as packec. This will change status to 'packed' and add a date to the sale.
                                    2. Button for marking as shipped. This will change status to 'shipped' and add a date to the sale.
                                    3. Field for adding tracking number.
                                */}

                                <div class='w-100 d-flex flex-row justify-content-center'>
                                    {/* <xmp>{JSON.stringify(newSale, null, 4)}</xmp> */}

                                    <button
                                        disabled={newSale.packedDate}
                                        class={`btn btn-${newSale.packedDate ? 'success' : 'primary'} rounded-pill`}
                                        onClick={this.markAsPacked}
                                    >
                                        <nobr>
                                            {newSale.packedDate ? <>
                                                <Text id='dyrejournal.marked-as-packed'>Packed</Text>:
                                                {util.formatDate(newSale.packedDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                            </> : <>
                                                <Text id='dyrejournal.mark-as-packed'>Mark as packed</Text>
                                            </>}
                                        </nobr>
                                    </button>
                                    <button
                                        disabled={!newSale.packedDate || newSale.shippingDate}
                                        class={`btn btn-${newSale.packedDate ? '' : 'outline-'}${newSale.shippingDate ? 'success' : 'primary'} rounded-pill ml-2`}
                                        onClick={this.markAsShipped}
                                    >
                                        <nobr>
                                            {newSale.shippingDate ? <>
                                                <Text id='dyrejournal.marked-as-shipped'>Shipped</Text>:
                                                {util.formatDate(newSale.shippingDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                            </> : <>
                                                <Text id='dyrejournal.mark-as-shipped'>Mark as shipped</Text>
                                            </>}
                                        </nobr>
                                    </button>

                                    <div class='input-group position-relative mb-0 ml-2'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-magnifying-glass'></i>
                                            </span>
                                        </div>
                                        <input
                                            disabled={!newSale.shippingDate}
                                            class={`form-control rounded-pill-right`}
                                            type='text'
                                            value={newSale.trackingNumber || ''}
                                            placeholder={`Tracking number`}
                                            onInput={this.saveTrackingNumber}
                                            // onKeyDown={this.handleKeyDown}
                                            // onFocus={this.setFocus}
                                            // ref={c => this.searchInputField = c}
                                            // onBlur={this.unsetFocus}
                                        />
                                    </div>
                                    <div class='input-group position-relative mb-0 ml-2'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-magnifying-glass'></i>
                                            </span>
                                        </div>
                                        <input
                                            disabled={!newSale.shippingDate}
                                            class={`form-control rounded-pill-right`}
                                            type='text'
                                            value={newSale.shippingMethod || ''}
                                            placeholder={`Shipping method`}
                                            onInput={this.saveShippingMethod}
                                            // onKeyDown={this.handleKeyDown}
                                            // onFocus={this.setFocus}
                                            // ref={c => this.searchInputField = c}
                                            // onBlur={this.unsetFocus}
                                        />
                                    </div>
                                </div>

                            </div>

                        </>}

                    </div>


                    <div class='bg-light d-flex flex-column justify-content-start pt-3'>
                        <DyrejournalSaleReceipt stores={this.props.stores} classnameContainer={'inlineReceipt'} />
                    </div>
                    <div class='bg-light d-flex flex-column justify-content-center pt-3'>
                        <button
                            class={`btn btn-info ml-2 rounded-pill`}
                            onClick={this.onClickPrintPacklist}
                        >
                            <i class='fa-solid fa-print' /> <Text id='dyrejournal.print-packlist'>Print packlist</Text>
                        </button>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default SaleShipping;
