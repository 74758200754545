import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';

@observer
class VisitorListCommunicationMini extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    loadAll = async () => {
        const { visitorCellphone = '', visitorEmail = '' } = this.props;
        const { smsStore, mailSentStore } = this.props.stores;
        smsStore.updateKeyValue('smsList', []);
        mailSentStore.updateKeyValue('mailSentList', []);

        if (visitorCellphone) {
            await smsStore.load({
                query: {
                    cellphone: visitorCellphone,
                },
                limit: 20,
            });
        }
        if (visitorEmail) {
            await mailSentStore.load({
                query: {
                    to: visitorEmail,
                },
                limit: 20,
            });
        }
    }

    toggleView = e => {
        const { view } = e.target.closest('button').dataset;
        this.setState({ view });
    }

    onClickViewSms = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, salePaymentStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('viewSms', {
			height: drawerHeightMedium,
            id,
		}, drawerLevel + 2);
    }

    onClickViewEmail = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, salePaymentStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('viewMailSent', {
			height: drawerHeightMedium,
            id,
		}, drawerLevel + 2);
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.visitorCellphone !== this.props.visitorCellphone || nextProps.visitorEmail !== this.props.visitorEmail) {
            this.loadAll();
        }
    }

    render() {
        const { view = 'sms' } = this.state;
        const {
            visitors = [],
            saleIsLocked = false,
            classNames = '',
        } = this.props;
        const { userStore, smsStore, mailSentStore } = this.props.stores;
        const { smsList } = smsStore;
        const { mailSentList } = mailSentStore;

        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { language = 'no' } = user;

        return (<>
            <div class={`d-flex flex-column mt-1 p-2 ${classNames}`} style='max-height: 125px;'>

                <div class='d-flex flex-row justify-content-center'>
                    <button class={`btn btn-sm btn${view === 'sms' ? '' : '-outline'}-primary rounded-pill-left py-0`} onClick={this.toggleView} data-view='sms'>
                        <Text id='dyrejournal.sms'>SMS</Text>
                    </button>
                    <button class={`btn btn-sm btn${view === 'email' ? '' : '-outline'}-primary rounded-pill-right py-0`} onClick={this.toggleView} data-view='email'>
                        <Text id='dyrejournal.email'>Email</Text>
                    </button>
                </div>

                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mt-1`}>
                    <small>
                        {view === 'sms' ? <>
                            <table class='table table-striped table-sm mb-0'>
                                <thead>
                                    <tr>
                                        <th class='p-0'><Text id='dyrejournal.date'>Date</Text></th>
                                        <th class='text-center p-0 pl-2'><Text id='dyrejournal.message'>Message</Text></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(smsList && smsList.length > 0) && smsList.map(e => {
                                        return (<>
                                            <tr onClick={this.onClickViewSms} data-id={e.id}>
                                                <td class='p-0'>
                                                    <nobr>{util.formatDate(e.createdDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</nobr>
                                                </td>
                                                <td class='p-0 pl-2'>{e.message}</td>
                                            </tr>
                                        </>);
                                    })}
                                </tbody>
                            </table>
                        </> : <>
                            <table class='table table-striped table-sm mb-0'>
                                <thead>
                                    <tr>
                                        <th class='p-0'><Text id='dyrejournal.date'>Date</Text></th>
                                        <th class='text-center p-0 pl-2'><Text id='dyrejournal.subject'>Subject</Text></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(mailSentList && mailSentList.length > 0) && mailSentList.map(e => {
                                        return (<>
                                            <tr onClick={this.onClickViewEmail} data-id={e.id}>
                                                <td class='p-0'>
                                                    <nobr>{util.formatDate(e.createdDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</nobr>
                                                </td>
                                                <td class='p-0 pl-2'>{e.subject}</td>
                                            </tr>
                                        </>);
                                    })}
                                </tbody>
                            </table>
                        </>}
                    </small>
                </div>
            </div>


        </>);
    }
}

export default VisitorListCommunicationMini;





