import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';

import localUtil from '../../lib/util';
import Input from '../../components/form/input';

@observer
class FormFields extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
        this.updateTimer = {};
    }

    displayValue = (field, val) => {
        const { holdingStore } = this.props;
        // if (field.name === 'members' && holdingStore[field.lookupArray]) {
        //     const member = holdingStore[field.lookupArray].find((m) => m.id === parseInt(val, 10));
        //     if (member) {
        //         return localUtil.displayName(member, true);
        //     }
        // }
        return val;
    };

    displayValueArrayObject = (field, val) => {
        if (field.name === 'youtubeVideos') {
            return (<img src={localUtil.youtubeThumb(val.url)} style='max-height: 115px;' />);
        }
        if (field.name === 'parts') {
            return `${val.qty} x ${val.partNumber} ${val.name}`;
        }
        if (field.name === 'members') {
            return `${val.firstname} ${val.lastname} ${val.email}`;
        }
        if (field.name === 'tags') {
            return `${val.name}`;
        }
        if (field.name === 'inspections') {
            const values = [];
            for (let i = 1; i <= 5; i += 1) {
                if (val[`check${i}`]) {
                    values.push(val[`check${i}`]);
                }
            }
            if (val[`name`]) {
                values.push(val[`name`]);
            }
            return (<>
                <div class='d-flex w-100 flex-column'>
                    <div>
                        {val.images && <img src={val.images[0].s3MediumLink} style='max-height: 100px;' />}
                    </div>
                    {values.map((v, idx) => {
                        return (<>
                            <div class='w-100 d-flex flex-row text-left'>
                                <div class='mx-2'>
                                    <i class='fa-solid fa-check' />
                                </div>
                                <div class='flex-fill'>
                                    {v}
                                </div>
                            </div>
                        </>);
                    })}
                </div>
            </>);
        }
        if (field.name === 'medicine') {
            return field.displayArrayObject(val);
        }
        if (field.name === 'diagnoses') {
            return field.displayArrayObject(val);
        }
        return val.title;
    }

    renderField(field, line) {
        const {
            holdingStore,
        } = this.props;
        const apiServer = util.getApiServer();

        if (field.showQR) {
            return (<>
                <QRCodeSVG value={`${apiServer}/go/${field.getValue(line)}`} size={256} />
            </>);
        }
        if (field.showImage) {
            return (<>
                {field.getValue(line).map((image, idx) => {
                    return (<>
                        <img src={`${image.s3MediumLink}`} style='max-width: 100px;' />
                    </>);
                })}
            </>);
        }
        if (field.name === 'images') {
            return (<>
                <div class='d-flex w-100 flex-row'>
                    {line.images && line.images.length > 0 && line.images.map(image => {
                        return (<>
                            <div>
                                <img src={image.s3MediumLink} style='max-height: 100px;' />
                            </div>
                        </>);
                    })}
                </div>
            </>);
        }
        if (field.showFile) {
            return (<>
                {field.getValue(line).map((file, idx) => {
                    return (<>
                        <a
                            href={file.s3Link}
                            title={file.name}
                            target='_blank'
                            native
                            style='font-size: 2.0em;'
                        ><i class='fa-solid fa-file-pdf' /></a>
                    </>);
                })}
            </>);
        }
        if (field.name === 'youtubeVideos') {
            if (!field.getValue(line) || !field.getValue(line).length) {
                return (<></>);
            }
            return (<>
                {field.getValue(line).map((video, idx) => {
                    return (<>
                        <a
                            href={`https://www.youtube.com/watch?v=${video.url}`}
                            title={video.title}
                            target='_blank'
                            native
                            style='font-size: 2.0em;'
                        >
                            <img src={localUtil.youtubeThumb(video.url)} style='max-height: 80px;' />
                        </a>
                    </>);
                })}
            </>);
        }
        if (field.datalistName && Array.isArray(holdingStore[field.datalistName])) {
            const value = field.getValue(line);
            const key = field.datalistFieldKeyName;
            const item = holdingStore[field.datalistName].find((i) => i[key] === value);
            // console.log({ key, value, item }, holdingStore[field.datalistName]);
            const val = field.datalistFieldVal(item) || value;
            return (<>
                {val}
            </>);
        }
        if (field.type === 'datetime-local') {
            return (<>
                {util.formatDate(field.getValue(line), { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
            </>);
        }
        if (field.type === 'date') {
            return (<>
                {util.formatDate(field.getValue(line), { locale: 'nb-NO' })}
            </>);
        }

        if (field.type === 'file') {
            // {
            //     "name": "LYCKE-BERTRAM-THOFTE-2023-12-18-1129 (1).pdf",
            //     "size": 335321,
            //     "encoding": "7bit",
            //     "tempFilePath": "",
            //     "truncated": false,
            //     "mimetype": "application/pdf",
            //     "md5": "88decdbfe3a9e9265fa7067fc0fee062",
            //     "createdDate": "2023-12-18T12:26:48.724Z",
            //     "ext": ".pdf",
            //     "newFilename": "the-musher-573d4fc7-f82b-4d86-bdff-658d5ca96c6a.pdf",
            //     "s3Link": "https://dyrejournal.app/original/the-musher-573d4fc7-f82b-4d86-bdff-658d5ca96c6a.pdf",
            //     "bytes": 335321
            // }
            return (<>
                <div class='d-flex w-100 flex-row'>
                    {line[field.name] && line[field.name].length > 0 && line[field.name].map(obj => {
                        return (<>
                            <div>
                                <a href={obj.s3Link} target='_blank' native>
                                    <i class='fa-duotone fa-file' /> {obj.name} ({util.formatBytes(obj.bytes)})
                                </a>
                            </div>
                        </>);
                    })}
                </div>
            </>);
        }
        if (field.name === 'links') {
            // {
            //     "url": "https://www.vetconnectplus.com/results/index.html?patientId=4571913043345408&jobId=4571913043345408-14563823125",
            //     "name": "Blodprøver, urinprøve",
            //     "md5": "971b90440e82951aba2c51f4b75e3271",
            //     "date": "2023-12-18T12:23:01.548Z"
            // }
            return (<>
                <div class='d-flex w-100 flex-row'>
                    {line[field.name] && line[field.name].length > 0 && line[field.name].map(obj => {
                        return (<>
                            <div>
                                <a href={obj.url} target='_blank' native>
                                    <i class='fa-duotone fa-link' /> {obj.name}
                                </a>
                            </div>
                        </>);
                    })}
                </div>
            </>);
        }
        if (field.type === 'arrayObject') {
            return (<>
                <div class='d-flex w-100 flex-column'>
                    {line[field.name] && line[field.name].length > 0 && line[field.name].map(obj => {
                        return (<>
                            <div>
                                {/* <xmp>{JSON.stringify(obj, null, 2)}</xmp> */}
                                {this.displayValueArrayObject(field, obj)}
                            </div>
                        </>);
                    })}
                </div>
            </>);
        }
        if (field.type === 'textarea') {
            return (<>
                <div class='d-flex w-100'>
                    <Markdown markdown={field.getValue(line)} markedOpts={localUtil.getMarkdownOptions()} />
                </div>
            </>);
        }

        return (<>
            {field.getValue(line)}
        </>);
    }

    render() {
        const {
            sections,
            holdingStore,
            objectName,
            isNew,
            drawerLevel,
            lookupArray,
            rightHeader,
            rightHeaderClick = () => {},
            hideAdmin,
            fields,
            filter = fields ? f => fields.includes(f.name) : () => true,
            children,
        } = this.props;
        const { userStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const object = holdingStore[objectName];

        return (<>
            {sections && sections.map((section, idx) => {
                let sectionFieldIdx = 0;
                return (<>
                    <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                        <small class='text-uppercase'>
                            {section.name}
                            {rightHeader && <>
                                <div class='float-right' onClick={rightHeaderClick}>
                                    {rightHeader}
                                </div>
                            </>}
                        </small>
                    </div>
                    <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-4 d-flex flex-column`} style='font-size: 1.1em; border-radius: 15px;'>
                        {children}
                        {section.fields && section.fields.filter(filter).map((field, idx) => {
                            const isReadOnly = isAdmin ? (field.isReadOnly && !field.adminEditAllowed) : field.isReadOnly;
                            if ((field.isAdminField && hideAdmin) || (field.isAdminField && !isAdmin)) {
                                return (<></>);
                            }
                            if (isNew && field.hideOnCreate) {
                                return (<></>);
                            }
                            const line = field.getValue(object);

                            let datalist = field.datalist;
                            if (field.datalistName && Array.isArray(holdingStore[field.datalistName])) {
                                datalist = holdingStore[field.datalistName].map(item => {
                                    return [
                                        field.datalistFieldKey(item),
                                        field.datalistFieldVal(item),
                                    ];
                                });
                            }
                            sectionFieldIdx += 1;

                            return (<>
                                <div
                                    class={`d-flex flex-row align-items-center position-relative pl-1 pr-3 w-100`}
                                    style={`${sectionFieldIdx > 1 ? `border-top: 1px ${darkmode ? '#303030' : '#f0f0f0'} solid;` : ''}`}
                                >
                                    <div
                                        class={`d-flex justify-content-center align-items-center ${field.isAdminField && isAdmin ? 'text-danger' : 'text-muted '}`}
                                        style={`
                                            width: 44px;
                                            height: 44px;
                                            min-width: 44px;
                                            opacity: ${field.isAdminField && isAdmin ? '0.8' : '0.3'};
                                        `}
                                    >
                                        <i class={`${field.icon}`} />
                                    </div>
                                    <span class={`flex-grow-1 overflow-auto`}>
                                        {line ? <>
                                            <div class='p-3 position-relative'>
                                                <small class='text-muted position-absolute' style='top: 0px; left: 0px;'>
                                                    {field.title}
                                                </small>
                                                {this.renderField(field, object)}
                                            </div>
                                        </> : <>
                                            <span class={`text-muted font-weight-lighter ml-3`}>
                                                <i><Text id='form.field-empty'>No value for</Text> {field.title}</i>
                                            </span>
                                        </>}
                                    </span>
                                </div>
                            </>);
                        })}
                    </div>
                    <div class='mx-4 font-weight-lighter px-3 pb-1 text-muted mb-4' >
                        <small class=''>
                            {section.help}
                        </small>
                    </div>
                </>);
            })}
        </>);
    }
}

export default FormFields;
