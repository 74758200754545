import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';
import GuiListTicker from '../../components/gui/listTicker';
import GuiListFrontpage from '../../components/gui/listFrontpage';

import StarRating from '../../components/gui/starRating';

function mergeArrays(array1, array2) {
    const resultArray = array1.slice(); // Create a copy of array1 to avoid mutating the original

    for (let i = 0; i < array2.length; i++) {
        if (i < array1.length) {
            resultArray[i] = array2[i];
        } else {
            resultArray.push(array2[i]); // Add any additional items from array2 not present in array1
        }
    }

    return resultArray;
}
@withText(props => ({
    titleText: <Text id='product.title'>Products</Text>,
}))
@observer
class ProductList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            filterByTags: [],
        };
    }

    viewProduct = (e, props) => {
        const { view, race } = this.props;
        if (view) {
            return view(e, props);
        }
        const { id, customer } = props ? props : e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMediumLarge } = appState;
        // console.log('viewProduct', { id });
        appState.openDrawer('viewProduct', {
            id,
            race,
            customer,
            // title,
            height: drawerHeightMediumLarge,
            callback: () => {
                // appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    sortFunc = e => {
        const { appState, productStore } = this.props.stores;
        const { sortBy, sortTitle } = productStore;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightSmall } = appState;
        // console.log('sortFunc');
        appState.openDrawer('sortProduct', {
            height: drawerHeightSmall,
        }, drawerLevel + 1);
    }

    toggleTagFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { filterByTags: filterByTagsProps } = this.props;
        if (filterByTagsProps?.length > 0) {
            return;
        }
        const { tag } = e.target.closest('span').dataset;
        const { filterByTags } = this.state;
        if (filterByTags.includes(tag)) {
            const index = filterByTags.indexOf(tag);
            filterByTags.splice(index, 1);
        } else {
            filterByTags.push(tag);
        }
        this.setState({ filterByTags });
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.ownedByOther && <>
                        <span class={`badge badge-pill badge-danger mr-1 mt-1`}>
                            <span class='font-weight-lighter'>Owner:</span> {obj.ownerFirstname} {obj.ownerLastname}
                        </span>
                    </>}
                    {obj.parents?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-code-branch' />
                        </span>
                    </>}
                    {obj.url && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-link' />
                        </span>
                    </>}
                    {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                    {obj.loans?.length > 0 && obj.loans.map(loan => {
                        if (loan.endDate) {
                            return '';
                        }
                        const endDate = loan.endDate ? new Date(loan.endDate) : new Date();
                        const {
                            seconds,
                            minutes,
                            hours,
                            days,
                            weeks,
                            months,
                            years,
                        } = util.dateDiff(loan.startDate, endDate);
                        // console.log('viewTags', obj.startDate, { obj, seconds, minutes, hours, days, weeks, months, years })

                        return (<>
                            <span class={`badge badge-pill badge-${loan.endDate ? 'success' : 'warning'} mr-1 mt-1`}>
                                Loaned out <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                            </span>

                            {/* <div class='tag'>{JSON.stringify(loan)}</div> */}
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    filterByTags = (product) => {
        const { filterByTags, hideChildren } = this.state;
        const { filterByTags: filterByTagsProps } = this.props;
        if (hideChildren && product.parent) {
            return false;
        }
        if (filterByTagsProps?.length > 0) {
            return product.tags?.some(tag => filterByTagsProps.includes(tag.name));
        }
        if (filterByTags.length === 0) {
            return true;
        }
        // console.log('filterByTags', { product, filterByTags })
        return product.tags?.some(tag => filterByTags.includes(tag.name));
    }

    filter = () => {
        const { filterByTags } = this.state;
        if (filterByTags.length > 0) {
            return (<>
                {filterByTags.map(tag => {
                    return (<>
                        <span
                            class='badge badge-pill badge-secondary mr-1'
                            style='font-size: 1.0rem;'
                            onClick={this.toggleTagFilter}
                            data-tag={tag}
                        ><i class="fa-solid fa-circle-xmark"></i> {tag}</span>
                    </>);
                })}
            </>);
        }
    }

    toggleChildren = e => {
        e.preventDefault();
        e.stopPropagation();
        const { hideChildren } = this.state;
        this.setState({ hideChildren: !hideChildren });
    }

    showIncDec = obj => {
        return obj.isConsumable;
    }

    render() {
        const { appState, userStore, productStore } = this.props.stores;
        const { isDevelopment, path, apiServer, opts = {} } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { hideChildren } = this.state;

        const {
            sortBy,
            sortTitle,
        } = productStore;

        const { titleText } = this.props;
        const {
            title = titleText,
            products = [],
            classNames = '',
            hideEmpty,
            addItem,
            inc,
            dec,
            remove,
            editQty,
            viewTags = this.viewTags,
            deleteElement,
            rentOutElement,
            moveElement,
            addUsageData,
            editElement,
            refreshElement,
            filterByTags,
            help,
            children,

            rightBoxField = 'children',
            rightBoxFieldValue = e => e && e.length > 0 ? e.length : null,
            rightBoxFieldPostfix = 'products',
            // rightBoxFieldPostfixField = 'children',
            // rightBoxFieldPostfixValue = 'products',

            maxHeight = '90vh',
            tickerMaxHeight = '',
            tickerMinHeight = '',
            tickerImgHeight = '200px',
            tickerImgRatio = '3 / 4',
            width = '140px',
            imageStyles = '',

            showBylineHeader,
            showComments,
            showLikes,
            showCreateButton,
            showSoMeStyle = true,
            showFrontpageList,
            fullWidthOnly,
        } = this.props;

        const { cart = [] } = user;

        if (showFrontpageList) {
            return (<>
                <GuiListFrontpage
                    key={`product-list-frontpage`}
                    classNames={classNames}
                    stores={this.props.stores}
                    holdingStore={productStore}
                    view={this.viewProduct}
                    wrapperPropsFunc={e => {
                        return {
                        'data-customer': e.customer,
                        };
                    }}
                    // title={showHeader ? (inputTitle ? inputTitle : title) : undefined}
                    list={products.filter(this.filterByTags)}
                    // list={[]}
                    elTitle={e => e.name}
                    // elLink={e => `/article/${encodeURIComponent(e.name).toLocaleLowerCase()}/${e.uuidv4}`}
                    // elDescription={e => e.ingress}
                    elInfo1={e => e.price ? <>
                        <span class='font-weight-light' style='font-size: 1.5em;'>kr. {productStore.lineTotal(e, true, 1)}</span>
                    </> : ''}
                    // elInfo2={e => e.inStock ? <>
                    //     <span class='font-weight-light'>På lager: {e.inStock}</span>
                    // </> : ''}
                    elInfo2={e => ''}
                    elInfo3={e => {
                        const inBasket = cart.find(item => item.product === e.id);
                        if (inBasket) {
                            return (<>
                                <i class='fa-duotone fa-check-circle text-success' style='font-size: 2.0em;' />
                                {/* {inBasket.qty} */}
                            </>);
                        }
                    }}
                    hideEmpty={hideEmpty}
                    editElement={editElement}
                    help={help || children}
                    showFilterBox={false}
                    maxHeight={tickerMaxHeight}
                    minHeight={tickerMinHeight}
                    imgHeight={tickerImgHeight}
                    imgRatio={tickerImgRatio}
                    width={width}
                    viewTags={this.viewTags}
                    showEmptyImage={false}
                    showSoMeStyle={showSoMeStyle}
                    showQty={false}

                    listWrapperClassnames={fullWidthOnly ? [] : [
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',

                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                        'rounded-md',
                    ]}
                    listContainerClassnames={fullWidthOnly ? [] : [
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',

                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                        'w-50 px-2',
                    ]}
                    listClasses={fullWidthOnly ? [] : [
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',

                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                        'flex-column',
                    ]}
                    listStyles={[]}
                    listTitleStyles={fullWidthOnly ? [] : [
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',

                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                        'font-size: clamp(0.9rem, 4vw, 2rem); font-weight: 700; line-height: 1.2em;',
                    ]}
                    listMaxImageWidth={fullWidthOnly ? [] : [
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',

                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                        '100%',
                    ]}
                    listImageLocations={fullWidthOnly ? [] : [
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',

                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                        'top',
                    ]}
                    listWrapperClassNameFull={'w-100'}


                />
            </>);
        }

        return (<>
            <GuiListTicker
                key={`product-list-ticker`}
                classNames={classNames}
                stores={this.props.stores}
                holdingStore={productStore}
                view={this.viewProduct}
                wrapperPropsFunc={e => {
                    return {
                      'data-customer': e.customer,
                    };
                }}
                // title={showHeader ? (inputTitle ? inputTitle : title) : undefined}
                list={products.filter(this.filterByTags)}
                // list={[]}
                elTitle={e => e.name}
                // elLink={e => `/article/${encodeURIComponent(e.name).toLocaleLowerCase()}/${e.uuidv4}`}
                // elDescription={e => e.ingress}
                elInfo1={e => e.price ? <>
                    <span class='font-weight-light' style='font-size: 1.5em;'>kr. {productStore.lineTotal(e, true, 1)}</span>
                </> : ''}
                // elInfo2={e => e.inStock ? <>
                //     <span class='font-weight-light'>På lager: {e.inStock}</span>
                // </> : ''}
                elInfo2={e => ''}
                elInfo3={e => ''}

                hideEmpty={hideEmpty}
                editElement={editElement}
                help={help || children}
                showFilterBox={false}
                maxHeight={tickerMaxHeight}
                minHeight={tickerMinHeight}
                imgHeight={tickerImgHeight}
                imgRatio={tickerImgRatio}
                width={width}
                viewTags={this.viewTags}
                showEmptyImage={false}
                showSoMeStyle={showSoMeStyle}
            />
        </>);

        return (<>
            <Localizer>
                <GuiList
                    stores={this.props.stores}
                    holdingStore={productStore}
                    classNames={classNames}
                    view={this.viewProduct}
                    title={title}
                    filter={this.filter}
                    list={products.filter(this.filterByTags)}
                    sortBy={sortBy}
                    sortTitle={sortTitle}
                    sortFunc={opts.showSortProducts ? this.sortFunc : null}
                    // toggle={hideChildren}
                    // toggleTitle={<Text id='product.filter.hide-children'>hide children</Text>}
                    // toggleFunc={this.toggleChildren}
                    elTitle={e => e.name}
                    elInfo1={e => e.price ? <>
                        <span class='font-weight-light'>a kr. {e.price}</span>
                    </> : ''}
                    elInfo2={e => e.inStock ? <>
                        <span class='font-weight-light'>På lager: {e.inStock}</span>
                    </> : ''}
                    elInfo3={e => ''}
                    viewTags={viewTags}
                    hideEmpty={hideEmpty}
                    showQty={false}
                    // addItem={addItem}
                    // showIncDec={this.showIncDec}
                    // inc={inc}
                    // dec={dec}
                    // remove={remove}
                    // editQty={editQty}
                    deleteElement={deleteElement}
                    editElement={editElement}
                    refreshElement={refreshElement}
                    rentOutElement={rentOutElement}
                    moveElement={moveElement}
                    addUsageData={addUsageData}
                    imgHeight='70px'
                    help={help || children}
                    rightBoxField={rightBoxField}
                    rightBoxFieldValue={rightBoxFieldValue}
                    rightBoxFieldPostfix={rightBoxFieldPostfix}
                    // rightBoxFieldPostfixField={rightBoxFieldPostfixField}
                    // rightBoxFieldPostfixValue={rightBoxFieldPostfixValue}
                />
            </Localizer>
        </>);
    }
}

export default ProductList;
