import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import HelpText from '../../components/gui/helpText';
import FileList from '../../components/fileViewer/list';
import Input from '../../components/form/input';

import localUtil from '../../lib/util';

const MARKDOWN_OPTIONS = {
	pedantic: false,
	gfm: true,
	breaks: true,
	sanitize: false,
	smartLists: true,
	smartypants: true,
	xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

function calendarEventImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

function getFirstArrayElement(myarray) {
    if (Array.isArray(myarray) && myarray.length > 0) {
        return [myarray[0]];
    } else {
        return []; // Or handle the empty case as needed
    }
}

function timeUsed(start, end) {
    // check if start or end is missing
    if (!start || !end) {
        return '';
    }
    const timeUsed = util.dateDiff(start, end);
    if (timeUsed?.seconds >= 0) {
        return util.secToHms(timeUsed.seconds);
    }
}

@withText(props => ({
    searchTitle: <Text id='input.search-for-visitor'>Search for visitor</Text>,
    searchAnimal: <Text id='input.search-for-animal'>Search for animal</Text>,
}))
@observer
class CalendarEventView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { calendarEventStore, saleStore } = this.props.stores;
        calendarEventStore.updateKeyValue('calendarEvent', {});
        calendarEventStore.updateKeyValue('animals', []);
        calendarEventStore.updateKeyValue('visitors', []);
        saleStore.updateKeyValue('currentSale', {});
        await calendarEventStore.load(id, false, { addData: ['tags', 'animals', 'visitors'] });

        const { calendarEvent } = calendarEventStore;
        const currentSales = await saleStore.load({
            query: {
                calendarEvent: calendarEvent.id,
            },
            skipUpdate: true,
        });

        if (currentSales.length === 1) {
            saleStore.updateKeyValue('currentSale', currentSales[0]);
        }
    }

    editcalendarEvent = () => {
		const { id, drawerLevel = 1, callback = () => {} } = this.props;
		const { appState, calendarEventStore } = this.props.stores;
        const { calendarEvent, visitors, animals } = calendarEventStore;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editCalendarEvent', {
			height: drawerHeightMedium,
            calendarType: calendarEvent.calendarType,
            calendarId: calendarEvent.calendarId,
            startDate: calendarEvent.start,
			id,
            callback: event => {
                console.log('editcalendarEvent.callback');
                // callback(event);
            },
		}, drawerLevel + 1);
	}

    onClickEditAnimal = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore, animalStore } = this.props.stores;
		const { drawerHeightLarge } = appState;

		appState.openDrawer('editAnimal', {
			height: drawerHeightLarge,
            isNew: false,
            hideAdmin: true,
            id: parseInt(id, 10),
            callback: () => {
                // console.log('onClickEditAnimal callback');
                const { animal } = animalStore;
                const { newSale, visitorAnimals } = saleStore;
                const { animals } = newSale;
                const idx = animals.findIndex(a => a.id === animal.id);
                if (idx > -1) {
                    saleStore.updateObjectKeyArrValue('newSale', 'animals', idx, animal);
                }
                const idx2 = visitorAnimals.findIndex(a => a.id === animal.id);
                if (idx2 > -1) {
                    saleStore.updateKeyArrValue('visitorAnimals', idx2, animal);
                }
            },
		}, drawerLevel + 1);
    }

    onClickEditVisitor = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, saleStore, visitorStore } = this.props.stores;
		const { drawerHeightLarge } = appState;

		appState.openDrawer('editVisitor', {
			height: drawerHeightLarge,
            isNew: false,
            id: parseInt(id, 10),
            callback: () => {
                // console.log('onClickEditVisitor callback');
                const { visitor } = visitorStore;
                const { newSale, visitorResults } = saleStore;
                const { visitors } = newSale;
                const idx = visitors.findIndex(a => a.id === visitor.id);
                if (idx > -1) {
                    saleStore.updateObjectKeyArrValue('newSale', 'visitors', idx, visitor);
                }
                const idx2 = visitorResults.findIndex(a => a.id === visitor.id);
                if (idx2 > -1) {
                    saleStore.updateKeyArrValue('visitorResults', idx2, visitor);
                }
            },
		}, drawerLevel + 1);
    }

    loadAnimals = async visitorIds => {
        if (!util.isArray(visitorIds) || visitorIds.length === 0) {
            return false;
        }
        const { animalStore, saleStore } = this.props.stores;
        const response = await animalStore.load({
            query: {
                owner: visitorIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAnimals', response);
    }

    loadSales = async visitorIds => {
        if (!util.isArray(visitorIds) || visitorIds.length === 0) {
            return false;
        }
        const { saleStore, calendarEventStore } = this.props.stores;
        const response = await saleStore.load({
            query: {
                visitor: visitorIds,
                today: 1,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('currentVisitorSales', response);
    }

    markedAsArrived = async () => {
        const { calendarEventStore } = this.props.stores;
        const { calendarEvent } = calendarEventStore;
        await calendarEventStore.saveField(calendarEvent.id, 'arrivedTime', new Date(), true);
        await calendarEventStore.saveField(calendarEvent.id, 'hasArrived', true, true);
    }

    markedAsReceived = async () => {
        const { calendarEventStore } = this.props.stores;
        const { calendarEvent } = calendarEventStore;
        await calendarEventStore.saveField(calendarEvent.id, 'receivedTime', new Date(), true);
    }

    markedAsDone = async () => {
        const { calendarEventStore } = this.props.stores;
        const { calendarEvent } = calendarEventStore;
        await calendarEventStore.saveField(calendarEvent.id, 'doneTime', new Date(), true);
    }

    createNewVisit = async () => {
        const { drawerLevel } = this.props;
        const { appState, userStore, calendarEventStore, saleStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { calendarEvent, visitors, animals } = calendarEventStore;

        // Update calendarEvent and mark it as arrived at the clinic.
        // calendarEventStore.saveField(calendarEvent.id, 'arrived', true);
        await calendarEventStore.saveField(calendarEvent.id, 'hasArrived', true, true);
        await calendarEventStore.saveField(calendarEvent.id, 'arrivedTime', new Date(), true);

        saleStore.createNewSale();
        saleStore.updateObjectKeyValue('newSale', 'visitors', getFirstArrayElement(visitors));
        saleStore.updateObjectKeyValue('newSale', 'animals', getFirstArrayElement(animals));
        saleStore.updateObjectKeyValue('newSale', 'calendarEvent', calendarEvent.id);

        const finalVisitors = getFirstArrayElement(visitors);
        const visitorIds = finalVisitors.length > 0 ? finalVisitors.map(v => v.id) : [];
        if (visitorIds.length > 0) {
            await this.loadAnimals(visitorIds);
            await this.loadSales(visitorIds);
        }
        route(`/dyrejournal-sale/`);
        appState.toggleDrawer(drawerLevel);
    }

    viewCustomerCard = async () => {
        const { drawerLevel } = this.props;
        const { appState, userStore, calendarEventStore, saleStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { calendarEvent, visitors, animals } = calendarEventStore;

        saleStore.createNewSale();
        saleStore.updateObjectKeyValue('newSale', 'visitors', getFirstArrayElement(visitors));
        saleStore.updateObjectKeyValue('newSale', 'animals', getFirstArrayElement(animals));

        const finalVisitors = getFirstArrayElement(visitors);
        const visitorIds = finalVisitors.length > 0 ? finalVisitors.map(v => v.id) : [];
        if (visitorIds.length > 0) {
            await this.loadAnimals(visitorIds);
            await this.loadSales(visitorIds);
        }
        route(`/dyrejournal-sale/`);
        appState.toggleDrawer(drawerLevel);
    }

    goToSale = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, saleStore } = this.props.stores;

        saleStore.createNewSale({});

        const newSale = await saleStore.load(parseInt(id, 10), false, { skipUpdate: true });
        if (id) {
            await this.loadSalePayments(parseInt(id, 10));
        }
        if (newSale[0].visitors) {
            const visitorIds = newSale[0].visitors.map(e => e.id);
            // console.log('onClickViewVisit', visitorIds)
            await this.loadAnimals(visitorIds);
            await this.loadSales(visitorIds);
        }

        if (newSale[0].animals) {
            const journalIds = newSale[0].journals.map(e => e.id);
            const animalIds = newSale[0].animals.map(e => e.id);
            await this.loadJournals(animalIds, newSale[0].id, journalIds);
        }
        saleStore.updateKeyValue('newSale', newSale[0]);
        const { visitors, isProductionAnimal } = newSale[0];

        const isProductionAnimals = util.isDefined(isProductionAnimal) ? isProductionAnimal : visitors.some(v => v?.producerNumber);
        saleStore.updateKeyValue('isProductionAnimals', isProductionAnimals);

        route(`/dyrejournal-sale/`);
        appState.toggleDrawer(drawerLevel);
        appState.appContainerScrollTop();
    }

    loadSalePayments = async saleId => {
        if (!util.isNumber(saleId)) {
            return false;
        }
        const { salePaymentStore, saleStore } = this.props.stores;
        const response = await salePaymentStore.load({
            query: {
                sale: saleId,
            },
            skipUpdate: true,
            limit: 5000,
        });
        saleStore.updateKeyValue('currentPayments', response);
    }

    loadJournals = async (animalIds, sale, journalIds) => {
        const { journalStore, saleStore } = this.props.stores;
        const response = await journalStore.load({
            query: {
                id: journalIds,
                // $or: [
                //     {
                //         animal: animalIds,
                //         sale,
                //     },
                //     {
                //         id: { $in: journalIds },
                //     },
                // ],
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('animalJournals', response);
    }

    didNotShow = () => {
        const { drawerLevel } = this.props;
        const { appState, userStore, calendarEventStore, saleStore } = this.props.stores;
        // TODO: Add action for did not show!
        const { calendarEvent } = calendarEventStore;
        if (calendarEvent.id) {
            calendarEventStore.saveField(calendarEvent.id, 'didNotShow', true);
            calendarEventStore.updateField(calendarEvent.id, 'didNotShow', true);
            calendarEventStore.deleteElement(calendarEvent.id);
        }
        appState.toggleDrawer(drawerLevel);
    }

    deleteBooking = () => {
        const { drawerLevel, callback = () => {} } = this.props;
        const { appState, userStore, calendarEventStore, saleStore } = this.props.stores;
        // TODO: Add action for did not show!
        const { calendarEvent } = calendarEventStore;
        if (calendarEvent.id) {
            calendarEventStore.saveField(calendarEvent.id, 'offline', 1);
            calendarEventStore.updateField(calendarEvent.id, 'offline', 1);
            callback({ delete: true, id: calendarEvent.id, calendarId: calendarEvent.calendarId});
        }
    }

    addTag = e => {
        const { tagInput = '' } = this.state;
        const { calendarEventStore } = this.props.stores;
        const value = {
            name: tagInput.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const field = 'tags';
        const object = calendarEventStore.calendarEvent;
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            calendarEventStore.saveField(object.id, field, value);
            calendarEventStore.updateField(object.id, field, [...localValue, value]);
        }
        this.setState({ tagInput: '' });
    }

    checkForEnter = (e) => {
        if (e.key === 'Enter') {
            this.addTag();
        }
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        const { visitorStore } = this.props.stores;
        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await visitorStore.searchVisitors({ search });
        } else {
            visitorStore.updateKeyValue('searchResult', []);
        }
    }

    clearSearch = () => {
        this.setState({ search: '' });
        this.doSearch('');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    chooseVisitor = async e => {
        const { id } = e.target.closest('button').dataset;
        const { animalStore, visitorStore } = this.props.stores;
        await animalStore.searchOwnerAnimals({ owner: id });
        visitorStore.updateKeyValue('searchResult', []);
    }

    chooseAnimal = async e => {
        const { id } = e.target.closest('button').dataset;
        const { calendarEventStore, animalStore, visitorStore } = this.props.stores;
        const { calendarEvent } = calendarEventStore;
        const { searchResult } = animalStore;
        const animal = searchResult.find(a => a.id === parseInt(id, 10));
        const value = {
            ...animal,
            md5: md5(JSON.stringify(animal)).toString(),
        };
        await calendarEventStore.saveField(calendarEvent.id, 'animals', value, true);
        await calendarEventStore.saveField(calendarEvent.id, 'title', `${calendarEvent.title}: ${animal.name}`, true);
        visitorStore.updateKeyValue('searchResult', []);
        animalStore.updateKeyValue('searchResult', []);

        await this.loadAll();
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            search,
            searchTitle,
            showEdit = false,
        } = this.props;
        const {
            sessionid,
            showMenu,
            tagInput,
        } = this.state;
        const { appState, userStore, calendarEventStore, saleStore, visitorStore, animalStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { calendarEvent = {}, visitors, animals, creators } = calendarEventStore;
        const { newSale, currentSale } = saleStore;

        const { searchResult } = visitorStore;
        const { searchResult: searchResultAnimal } = animalStore;

        const contentCreator = creators.find(creator => creator.id === calendarEvent.user);

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            {showEdit && <div class='d-flex w-100 justify-content-start pr-5 mt-3'>
                <button onClick={this.editcalendarEvent} class={`btn btn-sm btn-outline-primary rounded-pill ml-3`}>
                    <i class={`fa-regular fa-pen mr-2`} />
                    <Text id='calendar.event.edit'>Edit event</Text>
                </button>
            </div>}
            <div class='w-100 d-flex justify-content-center'>

                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <xmp>{JSON.stringify(currentSale, null, 4)}</xmp> */}
                    <div class={`d-flex justify-content-start flex-row w-100`}>
                        <div class='flex-fill'>
                            {/* <h5 class='ml-3 mb-3'>{calendarEvent.title}</h5> */}
                        </div>
                    </div>
                    {calendarEvent.backgroundColor && <>
                        <div class='w-100 d-flex flex-column'>
                            <div
                                class='d-flex flex-column mx-3 rounded-lg px-4 py-0 mt-2'
                                style={`
                                    background-color: ${calendarEvent.backgroundColor};
                                    font-size: 0.8rem;
                                    line-height: 0.8rem;
                                `}
                            >
                                &nbsp;
                            </div>
                        </div>
                    </>}
                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex'>
                                {/* {JSON.stringify(calendarEvent)} */}
                                <ImageScroller images={calendarEvent.images} stores={this.props.stores} showImg={img => calendarEventImg(img, this.props, '1920x')} showImageInfo={true} />
                            </div>

                            {calendarEvent.files?.length > 0 && calendarEvent.files.map(file => {
                                // console.log({ file });
                                if (file.s3Link.match(/\.(m4v|m4a|mp4)$/i)) {
                                    return (<>
                                        <div className='video-container rounded-lg'>
                                            <video controls className='video-player'>
                                                <source src={file.s3Link} type='video/mp4' />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div class='video-info d-flex flex-row justify-content-center'>
                                            <span class='text-muted'>size:</span> {localUtil.formatFileSize(file.size)}
                                            <span class='text-muted ml-3 mr-1'>encoding:</span> {file.encoding}
                                            <span class='text-muted ml-3 mr-1'>mimetype:</span> {file.mimetype}
                                            <span class='text-muted ml-3 mr-1'>ext:</span> {file.ext}
                                            <span class='text-muted ml-3 mr-1'>s3Link:</span> <a href={file.s3Link} target='_blank' rel='noopener noreferrer'>download</a>
                                        </div>
                                    </>);
                                }
                            })}

                            {calendarEvent.didNotShow && <div class='d-flex flex-row justify-content-center'>
                                <span class='badge badge-danger rounded-pill px-3'><Text id='dyrejournal.calendar-event.did-not-show'>Did not show</Text></span>
                            </div>}

                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3 mt-3`}>
                                {[
                                    'calendarId',
                                    'title',
                                    'body',
                                    'start',
                                    'end',
                                    'hasArrived',
                                    'skipReminders',
                                    ].map(key => {
                                    // if (util.isUndefined(calendarEvent[key])) {
                                    //     return '';
                                    // }
                                    const field = fields.getField('calendarEvent', key);
                                    if (!field) {
                                        console.log('Missing field:', key);
                                    }
                                    const displayValue = field.displayValue || (value => value);
                                    return (<>
                                        <div class='d-flex flex-row'>
                                            <span class='text-muted font-weight-light'>
                                                {field.title}
                                            </span>
                                            {field.type === 'textarea' ? <>
                                                <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                    <Markdown markdown={displayValue(calendarEvent[key] || '', calendarEvent)} markedOpts={MARKDOWN_OPTIONS} />
                                                </div>
                                            </> : <>
                                                <span class='ml-2'>
                                                    {field.type === 'toggle' ? <>
                                                        {calendarEvent[key] ? <>
                                                            <i class='fa-solid fa-check text-success' />
                                                        </> : <>
                                                            <i class='fa-solid fa-times text-danger' />
                                                        </>}
                                                    </> : <>
                                                        {displayValue(calendarEvent[key], calendarEvent)}
                                                    </>}
                                                </span>
                                            </>}
                                        </div>
                                    </>);
                                })}
                            </div>

                            {calendarEvent.isRecurring && <>
                                <div class='w-100 d-flex flex-sm-row flex-column mt-0'>
                                    <div class='w-100 d-flex flex-column'>
                                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mt-3'>
                                            <Text id='dyrejournal.calendar-recurring'>Recurring</Text>
                                        </div>
                                        <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                            {[
                                                'isRecurring',
                                                'recurringStart',
                                                'recurringEnd',
                                                'recurringFrequency',
                                            ].map(key => {
                                                // if (util.isUndefined(calendarEvent[key])) {
                                                //     return '';
                                                // }
                                                const field = fields.getField('calendarEvent', key);
                                                if (!field) {
                                                    console.log('Missing field:', key);
                                                }
                                                const displayValue = field.displayValue || (value => value);
                                                return (<>
                                                    <div class='d-flex flex-row'>
                                                        <span class='text-muted font-weight-light'>
                                                            {field.title}
                                                        </span>
                                                        {field.type === 'textarea' ? <>
                                                            <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                                <Markdown markdown={displayValue(calendarEvent[key] || '', calendarEvent)} markedOpts={MARKDOWN_OPTIONS} />
                                                            </div>
                                                        </> : <>
                                                            <span class='ml-2'>
                                                                {field.type === 'toggle' ? <>
                                                                    {calendarEvent[key] ? <>
                                                                        <i class='fa-solid fa-check text-success' />
                                                                    </> : <>
                                                                        <i class='fa-solid fa-times text-danger' />
                                                                    </>}
                                                                </> : <>
                                                                    {displayValue(calendarEvent[key], calendarEvent)}
                                                                </>}
                                                            </span>
                                                        </>}
                                                    </div>
                                                </>);
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>}

                            <div class='w-100 d-flex flex-sm-row flex-column mt-0'>
                                <div class='w-100 d-flex flex-column'>
                                    <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mt-3'>
                                        <Text id='dyrejournal.animal.title'>Animal</Text>
                                    </div>
                                    <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                        {animals.length === 0 && <>
                                            <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                                                <div class='w-100 d-flex flex-row justify-content-center'>
                                                    <div class='box-line-add'>
                                                        <i class='fa-solid fa-circle-question' />
                                                    </div>
                                                </div>
                                            </div>



                                            <div class='flex-fill input-group'>
                                                <div class='input-group-prepend'>
                                                    <span class='input-group-text rounded-pill-left'>
                                                        <i class='fa-regular fa-magnifying-glass'></i>
                                                    </span>
                                                </div>
                                                <input
                                                    class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                                                    type='text'
                                                    value={search}
                                                    placeholder={`${searchTitle}`}
                                                    onInput={this.searchInput}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                                {search && <div class='input-group-append'>
                                                    <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                                        <i class='fa-solid fa-circle-xmark'></i>
                                                    </button>
                                                </div>}
                                            </div>
                                            {searchResult && searchResult.length ? <>
                                                <div class='d-flex flex-wrap justify-content-center'>
                                                    {searchResult.map(user => {
                                                        return (<>
                                                            <button
                                                                class={`btn btn-sm btn-outline-primary rounded-pill mx-1 my-1`}
                                                                onClick={this.chooseVisitor}
                                                                data-id={user.id}
                                                            >
                                                                <i class='fa-duotone fa-plus' /> {localUtil.displayName(user)} {user.cellphone}
                                                            </button>
                                                        </>);
                                                    })}
                                                </div>
                                            </> : <></>}

                                            {searchResultAnimal && searchResultAnimal.length > 0 ? <>
                                                <div class='d-flex flex-wrap justify-content-center'>
                                                    {searchResultAnimal.map(a => {
                                                        return (<>
                                                            <button
                                                                class={`btn btn-sm btn-outline-success rounded-pill mx-1 my-1`}
                                                                onClick={this.chooseAnimal}
                                                                data-id={a.id}
                                                            >
                                                                <i class={`fa-duotone fa-plus`} /> {a.name}
                                                            </button>
                                                        </>);
                                                    })}
                                                </div>
                                            </> : <></>}


                                        </>}
                                        {animals.map(animal => {
                                            return (<>
                                                {[
                                                    'name',
                                                    'age',
                                                    'species',
                                                    // 'breed',
                                                    'chipId',
                                                    'chipIdChecked'
                                                ].map(key => {
                                                    const field = fields.getField('animal', key);
                                                    if (!field) {
                                                        console.log('Missing field:', key);
                                                    }
                                                    const displayValue = field.displayValue || (value => value || <span class='text-muted font-weight-lighter'>n/a</span>);
                                                    return (<>
                                                        <div class='d-flex flex-row'>
                                                            <span class='text-muted font-weight-light'>
                                                                {field.title}
                                                            </span>
                                                            {field.type === 'textarea' ? <>
                                                                <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                                    <Markdown markdown={displayValue(animal[key] || '', animal)} markedOpts={MARKDOWN_OPTIONS} />
                                                                </div>
                                                            </> : <>
                                                                <span class='ml-2'>
                                                                    {field.type === 'toggle' ? <>
                                                                        {animal[key] ? <>
                                                                            <i class='fa-solid fa-check text-success' />
                                                                        </> : <>
                                                                            <i class='fa-solid fa-times text-danger' />
                                                                        </>}
                                                                    </> : <>
                                                                        {displayValue(animal[key], animal)}
                                                                    </>}
                                                                </span>
                                                            </>}
                                                        </div>
                                                    </>);
                                                })}
                                                {(animal.cave || animal.isAggressive == 1) ? <>
                                                    <div class={`d-flex flex-row justify-content-between px-0 py-0  mt-2 animal-line`}>
                                                        <div class='flex-fill'>
                                                            <div class='p-2 mb-2 bg-danger text-white rounded-lg d-flex flex-column'>
                                                                {animal.cave ? <>
                                                                    <div>
                                                                        CAVE: {animal.cave}
                                                                    </div>
                                                                </> : <>
                                                                </>}
                                                                {animal.isAggressive ? <>
                                                                    <div>
                                                                        <i class='fa-solid fa-exclamation-triangle mr-2' /> <Text id='dyrejournal.aggressive'>Aggressive behavior</Text>
                                                                    </div>
                                                                </> : <>
                                                                </>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : <></>}
                                                <button
                                                    type='button'
                                                    class='btn btn-link text-muted p-0'
                                                    onClick={this.onClickEditAnimal}
                                                    data-id={animal.id}
                                                >
                                                    <i class='fa-duotone fa-pen' /> <Text id='dyrejournal.edit-animal'>Edit animal</Text>
                                                </button>
                                            </>);

                                        })}
                                    </div>
                                </div>
                                <div class='w-100 d-flex flex-column'>
                                    <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mt-3'>
                                        <Text id='dyrejournal.owner.title'>Owner</Text>
                                    </div>
                                    <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                        {visitors.length === 0 && <>
                                            <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                                                <div class='w-100 d-flex flex-row justify-content-center'>
                                                    <div class='box-line-add'>
                                                        <i class='fa-solid fa-circle-question' />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        {visitors.map(visitor => {
                                            return (<>
                                                {[
                                                    'firstname',
                                                    'lastname',
                                                    'cellphone',
                                                    'email',
                                                ].map(key => {
                                                    const field = fields.getField('visitor', key);
                                                    if (!field) {
                                                        console.log('Missing field:', key);
                                                    }
                                                    const displayValue = field.displayValue || (value => value || <span class='text-muted font-weight-lighter'>n/a</span>);
                                                    return (<>
                                                        <div class='d-flex flex-row'>
                                                            <span class='text-muted font-weight-light'>
                                                                {field.title}
                                                            </span>
                                                            {field.type === 'textarea' ? <>
                                                                <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                                    <Markdown markdown={displayValue(visitor[key] || '', visitor)} markedOpts={MARKDOWN_OPTIONS} />
                                                                </div>
                                                            </> : <>
                                                                <span class='ml-2'>
                                                                    {field.type === 'toggle' ? <>
                                                                        {visitor[key] ? <>
                                                                            <i class='fa-solid fa-check text-success' />
                                                                        </> : <>
                                                                            <i class='fa-solid fa-times text-danger' />
                                                                        </>}
                                                                    </> : <>
                                                                        {displayValue(visitor[key], visitor)}
                                                                    </>}
                                                                </span>
                                                            </>}
                                                        </div>
                                                    </>);
                                                })}
                                                {visitor.comment ? <>
                                                    <div class={`d-flex flex-row justify-content-between px-0 py-0  mt-2 animal-line`}>
                                                        <div class='flex-fill'>
                                                            <div class='p-2 mb-2 bg-secondary text-white rounded-lg d-flex flex-column'>
                                                                {visitor.comment ? <>
                                                                    <div>
                                                                        Info: {visitor.comment}
                                                                    </div>
                                                                </> : <>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : <></>}
                                                <button
                                                    type='button'
                                                    class='btn btn-link text-muted p-0'
                                                    onClick={this.onClickEditVisitor}
                                                    data-id={visitor.id}
                                                >
                                                    <i class='fa-duotone fa-pen' /> <Text id='dyrejournal.edit-owner'>Edit owner</Text>
                                                </button>
                                            </>);
                                        })}
                                    </div>
                                </div>
                            </div>

                            {/* <xmp>{JSON.stringify(calendarEvent.animals, null, 4)}</xmp> */}
                            {/* <xmp>{JSON.stringify(visitors, null, 4)}</xmp> */}
                            {/* <xmp>{JSON.stringify(animals, null, 4)}</xmp> */}

                            <div class={`d-flex flex-column mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                {calendarEvent.files?.length > 0 && <div class='d-flex flex-column'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='calendarEvent.files'>Files</Text>
                                    </span>
                                    <div class={`d-flex flex-column`}>
                                        <FileList files={calendarEvent.files} stores={this.props.stores} />
                                    </div>
                                </div>}

                                <Input
                                    stores={this.props.stores}
                                    holdingStore={calendarEventStore}
                                    field='tags'
                                    object={calendarEvent}
                                    objectName='calendarEvent'
                                    type='tags'
                                    lookupArray={'tags'}
                                />

                            </div>
                            <HelpText>
                                <Text id='calendarEvent.tag-help'>Add tags to this calendarEvent to make it easier to find and organize. Tip: Use general tags like: photo, fishing, car, boat...</Text>
                            </HelpText>

                        </div>
                    </div>
                    <div class='w-100 d-flex flex-column mt-3'>
                        <HelpText>
                            Created by: {contentCreator?.firstname || 'Unknown'} {contentCreator?.lastname || 'Unknown'} - {contentCreator?.cellphone || 'Unknown'}
                        </HelpText>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>

            <div class='w-100 d-flex flex-column justify-content-center mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <div class='d-flex flex-row justify-content-center'>
                    {currentSale && currentSale.id ? <>
                        <button
                            type='button'
                            class='btn btn-success rounded-pill px-5'
                            onClick={this.goToSale}
                            data-id={currentSale.id}
                        >
                            <i class='fa-duotone fa-arrow-up-right-from-square' /> <Text id='dyrejournal.go-to-customer-sale'>Go to sale</Text>
                        </button>
                    </> : <>
                        <button type='button' class='btn btn-primary rounded-pill mx-2' onClick={this.createNewVisit}>
                            <i class='fa-duotone fa-plus' /> <Text id='dyrejournal.go-to-customer-card'>Go to customer card</Text>
                        </button>
                        <button type='button' class='btn btn-outline-success rounded-pill mx-2' onClick={this.viewCustomerCard}>
                            <i class='fa-duotone fa-binoculars' /> <Text id='dyrejournal.view-customer-card'>View customer card</Text>
                        </button>
                        <button type='button' class='btn btn-danger rounded-pill mx-2' onClick={this.didNotShow}>
                            <i class='fa-duotone fa-person-circle-question' /> <Text id='dyrejournal.did-not-show'>Did not show</Text>
                        </button>
                        <button type='button' class='btn btn-outline-danger rounded-pill mx-2' onClick={this.deleteBooking}>
                            <i class='fa-duotone fa-trash' /> <Text id='dyrejournal.delete-booking'>Delete booking</Text>
                        </button>
                    </>}
                </div>
                <div class='d-flex flex-column justify-content-center'>
                    {/* arrivedTime: {calendarEvent.arrivedTime ? util.isoDate(calendarEvent.arrivedTime) : ''}<br /> */}
                    {/* receivedTime: {calendarEvent.receivedTime ? util.isoDate(calendarEvent.receivedTime) : ''}<br /> */}
                    {/* doneTime: {calendarEvent.doneTime ? util.isoDate(calendarEvent.doneTime) : ''}<br /> */}
                    <div class='d-flex flex-row justify-content-center'>

                        {calendarEvent.arrivedTime ? <>
                            {calendarEvent.receivedTime ? <>
                                {calendarEvent.doneTime ? <>
                                </> : <>
                                    <button type='button' class='btn btn-outline-secondary rounded-pill mt-2' onClick={this.markedAsDone}>
                                        <i class='fa-duotone fa-user-check' /> <Text id='dyrejournal.marked-as-done'>Mark as done</Text>
                                    </button>
                                </>}
                            </> : <>
                                <button type='button' class='btn btn-outline-secondary rounded-pill mt-2' onClick={this.markedAsReceived}>
                                    <i class='fa-duotone fa-user-doctor' /> <Text id='dyrejournal.marked-as-received'>Mark as received</Text>
                                </button>
                            </>}
                        </> : <>
                            <button type='button' class='btn btn-outline-secondary rounded-pill mt-2' onClick={this.markedAsArrived}>
                                <i class='fa-duotone fa-person-to-door' /> <Text id='dyrejournal.marked-as-arrived'>Mark as arrived</Text>
                            </button>
                        </>}
                    </div>
                    <div class='d-flex flex-row justify-content-center align-items-center'>
                        {calendarEvent.arrivedTime && <small class='mx-2'><span class='text-muted'><Text id='dyrejournal.time-waited-for-vet'>Time waited for vet</Text></span>: {timeUsed(calendarEvent.arrivedTime, calendarEvent.receivedTime || new Date())}</small>}
                        {calendarEvent.doneTime && <small class='mx-2'><span class='text-muted'><Text id='dyrejournal.time-with-vet'>Time with vet</Text></span>: {timeUsed(calendarEvent.receivedTime, calendarEvent.doneTime)}</small>}
                        {calendarEvent.receivedTime && <small class='mx-2'><span class='text-muted'><Text id='dyrejournal.time-in-clinic'>Time in clinic</Text></span>: {timeUsed(calendarEvent.arrivedTime, calendarEvent.doneTime || new Date())}</small>}
                    </div>


                </div>
            </div>

        </>);
    }
}

export default CalendarEventView;
