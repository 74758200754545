import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import util from 'preact-util';
import md5 from 'crypto-js/md5';

import DragNDropFileupload from './dragNdropFileupload';

@observer
class Images extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }

    handleAddImage = (file) => {
        // <Images saved={saved} object={dog} field={field} updateField={updateField} stores={this.props.stores} />
        // updateFieldValue = ({ id, field, value, datatype, elementtype, isNew = false, skipTimer = false }) => {
        const {
            addToImages = true,
            updateField,
            object,
            field,
        } = this.props;
// console.log('handleAddImage', { file, object, field });
        const images = [];
        images.push({
            ...file,
            md5: md5(JSON.stringify(file)).toString(),
            date: new Date(),
        });

        if (field) {
            updateField({ id: object.id, field, value: images, skipTimer: true });
        }

        // let images = toJS(object.images);
        // if (!util.isArray(images)) {
        //     images = [];
        // }
        // if (addToImages) {
        //     updateField({ id: object.id, field: 'images', value: images, skipTimer: true });
        // }
        // const allImages = [];
        // allImages.push(file);
        // updateField({ id: object.id, field: 'allImages', value: allImages, skipTimer: true });
    };

    render() {
        const {
            uploadStatus = () => {},
            before = () => {},
            after = () => {},
            autoOpen = true,
            text,
            icon,
            field,
        } = this.props;
        return (<>
            <DragNDropFileupload
                handleAddImage={this.handleAddImage}
                uploadStatus={uploadStatus}
                apiUrl={'/api/fileupload'}
                before={before}
                after={after}
                autoOpen={autoOpen}
                stores={this.props.stores}
                accept='image/*'
                imagesOnly={true}
                text={text}
                icon={icon}
                field={field}
            >
                {/* <div style={{ height: 30, width: '100%' }}> */}
                    {/* {files.map((file, idx) => (
                        <div key={idx}>
                            {file.name}
                            Total: {util.asString(file, 'load', 'total')}
                            <img style='width: 48px;'
                                alt='Preview'
                                key={file.preview}
                                src={file.src}
                            />
                        </div>
                    ))} */}
                {/* </div> */}
            </DragNDropFileupload>
        </>);
    }
}

export default Images;
