import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';

const LIMIT = 24;

function conventionImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

function participantExists(participants, key, value) {
    return participants.some(participant => participant[key] === value);
}

function memberExists(members, key, value) {
    return members.some(member => member[key] === value);
}

function generateArray(X) {
    const myNewShinyArray = [...Array(parseInt(X, 10) + 1).keys()];
    myNewShinyArray.shift();
    return myNewShinyArray;
}

@observer
class Convention extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.get('convention'),
        };
        this.mainContainer = null;
    }

    newConvention = e => {
        const { id, title } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, timetrackersStore } = this.props.stores;
		const { drawerHeightLarge } = appState;
		appState.openDrawer('newConvention', {
			height: drawerHeightLarge,
            callback: () => {
                appState.toggleDrawer();
                this.loadAll(this.props, false);
            },
		}, drawerLevel + 2);
	}

    viewConvention = (e, props) => {
        const { view } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = props ? props : e.target.closest('.box-line').dataset;
        return route(`/convention/${id}`);

        // const { appState } = this.props.stores;
        // const { drawerLevel = 1 } = this.props;
        // const { drawerHeightLarge } = appState;
        // appState.openDrawer('viewConvention', {
        //     id,
        //     height: drawerHeightLarge,
        //     // editFunction: this.editTimeConvention,
        //     callback: () => {
        //         appState.toggleDrawer();
        //         this.loadAll(this.props, false);
        //     },
        // }, drawerLevel + 1);
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    loadAll = async (props = this.props) => {
        const { id, currentUserOnly } = props;
        const { conventionStore, conventionResultStore, userStore } = this.props.stores;
        const { user = {} } = userStore;
        await conventionStore.load({ query: {
            participant: user.id,
            hostname: conventionStore.currentHostname,
        }, addData: ['tags', 'members'] });
        const conventionIds = conventionStore.conventions.map(e => e.id);
        await conventionResultStore.load({ query: {
            convention: conventionIds,
            user: user.id,
        } });
    }

    editParticipant = async e => {
        const { id, md5, stage } = e.target.closest('i').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
// console.log({ id, md5, stage });
        appState.openDrawer('editConventionParticipant', {
            id,
            md5,
            stage,
            height: drawerHeightSmall,
            callback: () => {
                this.loadAll(this.props, false);
            },
        }, drawerLevel + 1);
    }

    // getParticipantFields(convention) {
    //     if (convention.type === 'standard') {
    //         return ['150s', '150s', '150s', '150s', '20s', '20s', '20s', '20s', '10s', '10s', '10s', '10s'];
    //     }
    //     if (convention.type === 'hurtig') {
    //         return ['10s', '10s', '10s', '10s', '8s', '8s', '8s', '8s', '6s', '6s', '6s', '6s'];
    //     }
    //     if (convention.type === 'nais') {
    //         return ['150s', '150s', '5x3s', '5x3s', '20s', '10s'];
    //     }
    //     if (convention.type === 'norgesfelt') {
    //         return ['Treff', 'Skvr', 'iX', 'Trekk'];
    //     }
    //     if (convention.type === 'felt') {
    //         return ['Treff', 'Skvr', 'iX', 'Trekk'];
    //     }
    //     return [];
    // }

    filterParticipants = participant => {
        const { isConventionAdmin, showAll } = this.state;
        if (isConventionAdmin || showAll) {
            return true;
        }
        const { userStore } = this.props.stores;
        const { user } = userStore;
        return user.id === participant.id;
    }

    toggleShowAll = () => {
        this.setState({ showAll: !this.state.showAll });
    }

    toggleStats = () => {
        this.setState({ showStats: !this.state.showStats });
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const { showAll, showStats, offset = 0 } = this.state;
        const {
            classNames = '',
            title = 'Stevner / treninger',
            children,
            todayOnly,
        } = this.props;

        const { appState, userStore, conventionStore, conventionResultStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { conventions = [] } = conventionStore;
        const { conventionResults = [] } = conventionResultStore;

        const apiServer = util.getApiServer();
        const jwtToken = util.getJwtToken();

        const totalResults = conventions.length;
        const numIntervals = Math.ceil(totalResults / LIMIT);
        const intervals = Array.from({ length: numIntervals }, (_, index) => index * LIMIT);

        const yourStats = {
            type: {
            },
            class: {
            },
            topScore: {
            },
        };

        conventions?.forEach(convention => {
            convention.stages.forEach((stage, idx) => {
                const isFieldShooting = ['norgesfelt', 'felt'].includes(stage.type);
                const participants = convention[`participants${idx + 1}`];
                const you = participants.find(participant => participant.id === user.id);
                if (you) {
                    yourStats.type[stage.type] = yourStats.type[stage.type] || 0;
                    yourStats.type[stage.type] += 1;
                    const classKey = `${you.class || 'n/a'}`;
                    if (classKey) {
                        const { totalScore: tot, totalIx: totIx } = conventionResultStore.totalScore({ participant: you, convention, stage, isFieldShooting });
                        yourStats.topScore[stage.type] = yourStats.topScore[stage.type] || 0;
                        yourStats.topScore[stage.type] = Math.max(yourStats.topScore[stage.type], tot);

                        const key = `${stage.type}-${classKey}`;
                        yourStats.topScore[key] = yourStats.topScore[key] || 0;
                        yourStats.topScore[key] = Math.max(yourStats.topScore[key], tot);

                        yourStats.class[classKey] = yourStats.class[classKey] || 0;
                        yourStats.class[classKey] += 1;
                    }
                }
            });
        });

        const allTypes = Object.keys(yourStats.type);

        return (<>
            <button class={`btn btn-sm btn${showStats ? '' : '-outline'}-primary rounded-pill mt-2 mb-1`} onClick={this.toggleStats}>
                <i class='fa-duotone fa-chart-bar' /> <Text id='user.show-stats'>Show stats</Text>
            </button>
            {showStats && <>
                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mt-4'>
                    <Text id='convention.stats-shooting-programs'>Shooting programs</Text>
                </div>
                <div class={`d-flex flex-column justify-content-center mb-3 w-100 bg-light ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg p-2`}>
                    {/* <xmp>{JSON.stringify(allTypes, null, 2)}</xmp>
                    <xmp>{JSON.stringify(yourStats, null, 2)}</xmp> */}

                    <div class='table-reponsive w-100'>
                        <table class='table table-sm table-striped'>
                            <thead>
                                <tr>
                                    <th class='text-muted w-50'><Text id='convention.stats-program'>Program</Text></th>
                                    <th class='text-muted text-center w-10'><Text id='convention.stats-count'>Count</Text></th>
                                    <th class='text-muted text-center w-10'><Text id='convention.stats-top-score'>Top Score</Text></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(yourStats.type).map(type => {
                                    return (<>
                                        <tr>
                                            <td>
                                                {type}
                                            </td>
                                            <td class='text-center'>
                                                {yourStats.type[type]}
                                            </td>
                                            <td class='text-center'>
                                                {yourStats.topScore[type]}
                                            </td>
                                        </tr>
                                    </>);
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>


                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mb-1 mt-2'>
                    <Text id='convention.stats-shooting-weapons-types'>Weapon types</Text>
                </div>
                <div class={`d-flex flex-column justify-content-center mb-3 w-100 bg-light ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg p-2`}>
                    <div class='table-reponsive w-100 mt-2'>
                        <table class='table table-sm table-striped'>
                            <thead>
                                <tr>
                                    <th class='text-muted w-50'><Text id='convention.stats-class'>Class</Text></th>
                                    <th class='text-muted text-center w-10'><Text id='convention.stats-count'>Count</Text></th>
                                    {allTypes.map(type => {
                                        return (<>
                                            <th class='text-muted text-center w-5'>{type}</th>
                                        </>);
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(yourStats.class).map(klass => {
                                    return (<>
                                        <tr>
                                            <td>
                                                {klass}
                                            </td>
                                            <td class='text-center'>
                                                {yourStats.class[klass]}
                                            </td>
                                            {allTypes.map(type => {
                                                const key = `${type}-${klass}`;
                                                return (<>
                                                    <td class='text-center'>
                                                        {yourStats.topScore[key]}
                                                    </td>
                                                </>);
                                            })}
                                        </tr>
                                    </>);
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>}


            <div class='d-flex flex-row justify-content-center align-items-center mb-3'>
                <a
                    class='btn btn-success text-white rounded-pill mt-3 mx-1'
                    href={`${apiServer}/api/conventions/download?jwtToken=${jwtToken}&offset=${offset}`}
                    target='_blank'
                >
                    <i class='fa-duotone fa-download' /> <Text id='user.download-police-form'>Download police form</Text>
                </a>
                {conventions.length > LIMIT ? <>
                    <div class='mt-3 ml-2'>
                        <select
                            class={`form-control rounded-lg`}
                            onInput={linkstate(this, 'offset')}
                        >
                            <option value=''>-- Velg start --</option>
                            {intervals.map(start => (
                                <option value={start} key={start}>
                                    {start} - {Math.min(start + LIMIT, totalResults)}
                                </option>
                            ))}
                        </select>
                    </div>
                </> : <></>}
            </div>

            <div class='table-reponsive w-100'>
                <table class='table table-sm table-striped'>
                    <thead>
                        <tr>
                            <th class='text-muted w-5'>Dato</th>
                            <th class='text-muted w-5'>Runde</th>
                        </tr>
                    </thead>
                    <tbody>
                        {conventions && conventions.length ? <>
                            {conventions.map(convention => {
                                return (<>
                                    {convention.stages && convention.stages.length ? <>
                                        {convention.stages.map((stage, idx) => {
                                            const isFieldShooting = ['norgesfelt', 'felt'].includes(stage.type);
                                            const participants = convention[`participants${idx + 1}`];
                                            const youAreParticipant = participants && participants.some(participant => participant.id === user.id);
                                            if (!youAreParticipant && !showAll) {
                                                return (<></>);
                                            }

                                            return (<>
                                                <tr>
                                                    <td>
                                                        <nobr>
                                                            <span class='font-weight-lighter'>
                                                                {util.formatDate(convention.startDate, { weekday: 'short', hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                                            </span> - {convention.title}
                                                        </nobr><br />
                                                        <nobr>
                                                            <small>
                                                                {stage.name} / {stage.type}
                                                            </small>
                                                        </nobr>
                                                        {participants && participants.filter(this.filterParticipants).map((participant, participantIdx) => {
                                                            const isYou = participant.id === user.id;
                                                            if (isFieldShooting) {
                                                                return(<>
                                                                    <nobr class='ml-2'>
                                                                        {/* {localUtil.displayName(participant)} {isYou ? `(You)` : ''}  */}
                                                                        <span class='font-weight-lighter'> - {participant.class}</span>
                                                                        {isYou ? <>
                                                                            <i
                                                                                class='fa-solid fa-edit mt-2 ml-2'
                                                                                data-id={convention.id}
                                                                                data-md5={participant.md5}
                                                                                data-stage={stage.md5}
                                                                                onClick={this.editParticipant}
                                                                            />
                                                                        </> : <></>}
                                                                    </nobr>
                                                                </>);
                                                            }
                                                            return (<>
                                                                <nobr class='ml-2'>
                                                                    {/* {localUtil.displayName(participant)} {isYou ? `(You)` : ''}  */}
                                                                    <span class='font-weight-lighter'> - {participant.class}</span>
                                                                    {isYou ? <>
                                                                        <i
                                                                            class='fa-solid fa-edit mt-2 ml-2'
                                                                            data-id={convention.id}
                                                                            data-md5={participant.md5}
                                                                            data-stage={stage.md5}
                                                                            onClick={this.editParticipant}
                                                                        />
                                                                    </> : <></>}
                                                                </nobr>
                                                            </>);
                                                        })}
                                                    </td>
                                                    <td>

                                                        <table class='table table-sm my-0 rounded'>
                                                            <thead>
                                                                <tr>
                                                                    {isFieldShooting ? <>
                                                                        {conventionResultStore.generateArray(stage.stages || 10).map(stand => {
                                                                            return (<>
                                                                                <th class='text-muted text-center w-5'><small>{stand}</small></th>
                                                                            </>);
                                                                        })}
                                                                    </> : <>
                                                                        {conventionResultStore.getParticipantFields(stage).map((field, index) => {
                                                                            return (<>
                                                                                <th class='text-muted font-weight-lighter'><small>{field}</small></th>
                                                                            </>);
                                                                        })}
                                                                    </>}

                                                                    {['norgesfelt', 'felt'].includes(stage.type) ? <>
                                                                        <th class='text-muted text-center'><small><Text id='input.innerX'>iX</Text></small></th>
                                                                    </> : <></>}
                                                                    <th class='text-muted text-center'><small><Text id='input.totalScore'>Total</Text></small></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {participants && participants.filter(this.filterParticipants).map((participant, participantIdx) => {
                                                                    const participantResults = conventionResults.filter(result => result.participant === participant.id
                                                                        && result.stage === stage.md5
                                                                        && convention.id === result.convention
                                                                    );
                                                                    let totalScore = 0;
                                                                    let totalIx = 0;
                                                                    let standScore = 0;
                                                                    if (isFieldShooting) {
                                                                        return(<>
                                                                            <tr>
                                                                                {conventionResultStore.generateArray(stage.stages || 10).map(stand => {
                                                                                    standScore = 0;
                                                                                    const targetResult = participantResults.find(result => result.stand === stand);
                                                                                    standScore += targetResult?.scoreHits || 0;
                                                                                    standScore += targetResult?.scoreTargets || 0;
                                                                                    if (targetResult?.scorePenalty) {
                                                                                        standScore -= targetResult.scorePenalty;
                                                                                    }
                                                                                    totalScore += standScore;
                                                                                    totalIx += targetResult?.scoreInner || 0;
                                                                                    return (<>
                                                                                        <td class='text-center' data-id={targetResult?.id} onClick={this.viewImage}>
                                                                                            {standScore}/<span class='font-weight-lighter'>{targetResult?.scoreInner || 0}</span>
                                                                                        </td>
                                                                                    </>);
                                                                                })}
                                                                                <td class='text-center font-weight-lighter'>{totalIx}</td>
                                                                                <td class='text-right font-weight-bolder'>
                                                                                    <span style='font-size: 1.3rem; line-height: 1.2rem; font-weight: bold;'>
                                                                                        {totalScore}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </>);
                                                                    }

                                                                    return (<>
                                                                        <tr>
                                                                            {conventionResultStore.getParticipantFields(stage).map((field, index) => {
                                                                                const targetResult = participantResults.find(result => result.targetIndex === index);
                                                                                if (targetResult) {
                                                                                    totalScore += targetResult.scoreTotal;
                                                                                }
                                                                                return (<>
                                                                                    <td class='text-center'>
                                                                                        {targetResult?.scoreTotal}
                                                                                    </td>
                                                                                </>);
                                                                            })}
                                                                            <td class='text-right'>
                                                                                <span style='font-size: 1.3rem; line-height: 1.2rem; font-weight: bold;'>
                                                                                    {totalScore}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </>);
                                                                })}
                                                            </tbody>
                                                        </table>

                                                    </td>
                                                </tr>
                                            </>);
                                        })}
                                    </> : <></>}
                                </>);
                            })}
                        </> : <></>}

                    </tbody>
                </table>
            </div>
        </>);
    }
}

export default Convention;
