import { observable, configure, action, computed } from 'mobx';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class FikenStore {
    constructor() {

    }

    @observable slug = ''; // Demo kunde
    @observable contactId = ''; // Demo kunde
    @observable contactPersonId = ''; // Demo kunde
    @observable invoiceId = ''; // Demo kunde

    @action
    updateKeyValue(key, value) {
        this[key] = value;
    }

    @action
    updateObjectKeyValue(obj, key, value) {
        this[obj][key] = value;
    }

    @observable redirectUrl = null;

    async getUser() {
        const response = await util.fetchApi(`/api/integrations/fiken/user`);
        switch (response.status) {
            case 200:
                // console.log('fikenStore.getPaymentStatus', response);
                return response;
            default:
                console.error('fikenStore.getUsers', response);
                return response;
        }
    }

    async getCompanies() {
        const response = await util.fetchApi(`/api/integrations/fiken/companies`);
        switch (response.status) {
            case 200:
                // console.log('fikenStore.getPaymentStatus', response);
                return response;
            default:
                console.error('fikenStore.getCompanies', response);
                return response;
        }
    }

    async getCompanyContacts() {
        if (!this.slug) {
            return;
        }
        const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/contacts`);
        switch (response.status) {
            case 200:
                // console.log('fikenStore.getPaymentStatus', response);
                return response;
            default:
                console.error('fikenStore.getCompanyContacts', response);
                return response;
        }
    }

    async createCompanyContact() {
        if (!this.slug) {
            return;
        }
        const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/contacts`, {
            method: 'POST',
        }, {
            "name": "Fiken AS",
            "email": "kontakt@fiken.gmail",
            "organizationNumber": "913312465",
            "phoneNumber": "62158537",
            "memberNumber": 123456789,
            "customer": true,
            "supplier": false,
            "bankAccountNumber": "11112233334",
            "contactPerson": [
                {
                    "name": "Betty Boop",
                    "email": "bb@gmail.com",
                    "phoneNumber": "98573564",
                    "address": {
                        "streetAddress": "Karl Johan 34",
                        "streetAddressLine2": "H0405",
                        "city": "Oslo",
                        "postCode": "0550",
                        "country": "Norway"
                    }
                }
            ],
            "currency": "NOK",
            "language": "Norwegian",
            "inactive": false,
            "daysUntilInvoicingDueDate": 15,
            "address": {
                "streetAddress": "Karl Johan 34",
                "streetAddressLine2": "H0405",
                "city": "Oslo",
                "postCode": "0550",
                "country": "Norway"
            },
            // "groups": [
            //     "string"
            // ]
        });
        switch (response.status) {
            case 200:
                // console.log('fikenStore.createCompanyContact', response);
                return response;
            default:
                console.error('fikenStore.createCompanyContact', response);
                return response;
        }
    }


    async getCompanyContactsContactPerson() {
        if (!this.slug || !this.contactId) {
            return;
        }
        const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/contacts/${this.contactId}/contactPerson`);
        switch (response.status) {
            case 200:
                // console.log('fikenStore.getPaymentStatus', response);
                return response;
            default:
                console.error('fikenStore.getCompanyContacts', response);
                return response;
        }
    }

    async getCompanyInvoices() {
        if (!this.slug) {
            return;
        }
        const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/invoices`);
        switch (response.status) {
            case 200:
                // console.log('fikenStore.getPaymentStatus', response);
                return response;
            default:
                console.error('fikenStore.getCompanyInvoices', response);
                return response;
        }
    }

    async createCompanyInvoice(saleId) {
        if (!this.slug) {
            return;
        }
        if (saleId) {
            const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/invoices/${saleId}`, {
                method: 'POST',
            }, {});
            switch (response.status) {
                case 200:
                    // console.log('fikenStore.getPaymentStatus', response);
                    return response;
                default:
                    console.error('fikenStore.createCompanyInvoice', response);
                    return response;
            }
        }

        const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/invoices`, {
            method: 'POST',
        }, {
            "uuid": "e15c445c-30f7-4c4f-bd13-c8ff6aeb3ad4",
            "issueDate": "2024-09-30",
            "dueDate": "2024-10-15",
            "lines": [
                {
                    "netAmount": 4500,
                    "vatType": "HIGH",
                    "quantity": 5,
                    "unitPrice": 1200,
                    "discountPercent": 25,
                    "productName": "Gardening Gloves VI2",
                    "description": "Goatskin, with extra-long suede cuffs",
                    "comment": "One size fits all",
                    "incomeAccount": "3000"
                }
            ],
            "ourReference": "Øistein Sørensen",
            "yourReference": "Kari Nordmann",
            "orderReference": "123456",
            "customerId": this.contactId,
            "contactPersonId": this.contactPersonId,
            "bankAccountCode": "1920:10001",
            "currency": "NOK",
            "invoiceText": "Invoice for services rendered during the Oslo Knitting Festival.",
            "cash": false,
        });
        switch (response.status) {
            case 200:
                // console.log('fikenStore.getPaymentStatus', response);
                return response;
            default:
                console.error('fikenStore.createCompanyInvoice', response);
                return response;
        }
    }

    async sendCompanyInvoice(saleId) {
        if (!this.slug) {
            return;
        }
        if (saleId) {
            const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/invoices/send/${saleId}`, {
                method: 'POST',
            }, {});
            switch (response.status) {
                case 200:
                    // console.log('fikenStore.getPaymentStatus', response);
                    return response;
                default:
                    console.error('fikenStore.sendCompanyInvoice', response);
                    return response;
            }
        }
        const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/invoices/send`, {
            method: 'POST',
        }, {
            "method": [
                "email"
            ],
            "includeDocumentAttachments": true,
            "recipientName": "Øistein Sørensen",
            "recipientEmail": "sorenso@gmail.com",
            "message": "Due upon receipt",
            "emailSendOption": "document_link",
            "mergeInvoiceAndAttachments": false,
            "organizationNumber": "913312465",
            "mobileNumber": "91520191",
            "invoiceId": this.invoiceId,
        });
    }

    async getCompanySales() {
        if (!this.slug) {
            return;
        }
        const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/sales`);
        switch (response.status) {
            case 200:
                // console.log('fikenStore.getPaymentStatus', response);
                return response;
            default:
                console.error('fikenStore.getCompanySales', response);
                return response;
        }
    }

    async createCompanySale(saleId) {
        if (!this.slug) {
            return;
        }
        if (saleId) {
            const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/sales/${saleId}`, {
                method: 'POST',
            }, {});
            switch (response.status) {
                case 200:
                    // console.log('fikenStore.getPaymentStatus', response);
                    return response;
                default:
                    console.error('fikenStore.createCompanySale', response);
                    return response;
            }
        }

        const response = await util.fetchApi(`/api/integrations/fiken/companies/${this.slug}/sales`, {
            method: 'POST',
        }, {
            "saleNumber": "XK455L",
            "date": "2024-10-01",
            "kind": "external_invoice",
            "totalPaid": 5625,
            "lines": [
                {
                    "description": "sale of goods",
                    "netPrice": 4500,
                    "vat": 1125,
                    "vatType": "HIGH",
                    "account": "3000",
                }
            ],
            "customerId": this.contactId,
            "currency": "NOK",
            "dueDate": "2024-10-10",
            "kid": "5855454756",
            "paymentAccount": "1920:10001",
            "paymentDate": "2024-10-01",
            // "paymentFee": 634550,
            // "projectId": 2888156

        });
        switch (response.status) {
            case 200:
                // console.log('fikenStore.getPaymentStatus', response);
                return response;
            default:
                console.error('fikenStore.createCompanySale', response);
                return response;
        }
    }
}

const store = new FikenStore();
export default store;
