import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    articleNumber: <Text id='input.articleNumber-help'>articleNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewArticle extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('article'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, category, active, userOnly } = props;
        const { articleStore } = this.props.stores;
        if (emptyFirst) {
            articleStore.updateKeyValue('article', {});
            articleStore.updateKeyValue('newArticle', {});
        }
        if (id) {
            const query = {
                hostname: articleStore.currentHostname,
                all: 1,
            };
            if (userOnly) {
                query.userOnly = true;
            }


            await articleStore.load(id, false, { query, addData: ['tags'] });
        } else {
            articleStore.updateObjectKeyValue('newArticle', 'category', category);
            articleStore.updateObjectKeyValue('newArticle', 'active', active);
        }
    }

    createArticle = async () => {
        const { callback = () => {}, id, isNew, drawerLevel } = this.props;
        const { articleStore, appState } = this.props.stores;
        const { newArticle } = articleStore;

        if (isNew) {
            const response = await articleStore.insert({
                ...newArticle,
                hostname: articleStore.currentHostname,
            });
            articleStore.updateKeyValue('article', response?.data?.article);
            articleStore.updateKeyValue('newArticle', {});
            callback();
            appState.toggleDrawer(false, drawerLevel);
        }
    }

    chooseItem = (e) => {
        const { id } = e.target.closest('.similar-item').dataset;
        const { articleStore } = this.props.stores;
        const { foundList } = articleStore;
        const item = foundList.find((item) => item.id === parseInt(id, 10));
        articleStore.updateKeyValue('newArticle', {
            ...item,
        });
        articleStore.resetSearch();
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, installation, lookupArray = 'articles', userOnly, category } = this.props;
        const { sections, showSimilar } = this.state;
        const { userStore, articleStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newArticle, foundList, foundListKeys } = articleStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        //{section.fields && section.fields.filter(field => field.showInList).map((field, idx) => {
        const fields = userOnly ? sections.map(section => section.fields.filter(field => field.isSimpleEdit)).flat() : sections.map(section => section.fields).flat();
        if (category === 'marketplace') {
            const marketplaceFields = sections.map(section => section.fields.filter(field => field.isMarketPlace)).flat();
            fields.push(...marketplaceFields);
        }

        return (<>
            <div class='w-100 d-flex justify-content-center position-relative'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(installation, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='article.new'>New article</Text> : <Text id='article.edit'>Edit article</Text>}
                        {installation?.id && <>
                            <span class='ml-2'>
                                :: {installation.name}
                            </span>
                        </>}
                    </div>

                    {showSimilar && foundList?.length > 0 && <>
                        <div class='w-100 d-flex flex-column justify-content-start'>
                            <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                                <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                                    <small class='text-uppercase'>
                                        <Text id='article.similar'>Similar articles</Text>
                                    </small>
                                </div>
                                <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-4 d-flex flex-column px-0 overflow-auto`} style='font-size: 1.1em; border-radius: 15px; max-height: 35vh;'>
                                    {foundList.map((item, idx) => {
                                        return(<>
                                            <div class='d-flex flex-row justify-content-between align-items-center'>
                                                <div
                                                    class='flex-fill similar-item py-2 px-3'
                                                    data-id={item.id}
                                                    onClick={this.chooseItem}
                                                >
                                                    {item.name}
                                                    <span class='font-weight-lighter text-muted ml-3'>{item.articleNumber}</span>
                                                </div>
                                                {/* {item.images[0] && <>
                                                    <div>
                                                        <div class=''>
                                                            <img src={`${item.images[0].s3MediumLink}`} class='rounded-lg img-fluid' style='max-height: 45px;' />
                                                        </div>
                                                    </div>
                                                </>} */}
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </>}

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                fields={fields.map(field => field.name)}
                                holdingStore={articleStore}
                                objectName={isNew ? 'newArticle' : 'article'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                lookupArray={lookupArray}
                                rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar articles ({foundList.length})</span> : null}
                                rightHeaderClick={this.toggleSimilar}
                                hideFilters={true}
                            />
                        </div>
                    </div>
                </div>



            </div>

            {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <button type='button' class='btn btn-primary rounded-pill' onClick={this.createArticle}>
                    {isNew ? <Text id='article.create'>Create article</Text> : <Text id='article.save'>Save article</Text>}
                </button>
            </div>}
        </>);
    }
}

export default NewArticle;
