import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ProductStore extends LocalModel {
    constructor() {
        super('product', {
            namePlural: 'products',
            sort: 'productNumber',
            limit: 5000,
            api: {
                search: {
                    url: '/api/products/',
                    params: {
                        limit: 5000,
                        // sort: 'name',
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/products/',
                    params: {},
                },
                save: {
                    url: '/api/products/',
                    params: {},
                },
                delete: {
                    url: '/api/products/',
                    params: {},
                },
            },
        });
    }

    @observable newProduct = {};

    @observable product = {};

    @observable products = [];

    @observable allProducts = [];

    @observable searchResults = [];

    @observable productJournals = [];

    lineTotal(line, format = true, inputQty) {
        const qty = inputQty || line.qty;
        if (!qty) return 0;

        let aPrice = line.price;
        if (line.medicine || line.fee || line.equipment || line.other) {
            aPrice = parseFloat(line.medicine || 0) + parseFloat(line.fee || 0) + parseFloat(line.equipment || 0) + parseFloat(line.other || 0);
        }
        let price = qty * (aPrice || 0);
        if (line.discount) {
            price -= price * (line.discount / 100);
        }
        const taxAmount = price * (line.vat / 100);
        let totalPrice = price + taxAmount;
        if (line.discountAmount) {
            totalPrice -= (line.discountAmount * qty);
        }
        if (format) {
            return util.format(totalPrice, 2, ',', ' ');
        }
        return totalPrice;
    }

    async updateAllPrices({ account, category, percent }) {
        const response = await util.fetchApi(`/api/products/adjustallprices`, { method: 'PATCH' }, {
            percent,
            account,
            category,
        });
        switch (response?.status) {
            case 201:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }
}

const store = new ProductStore();
export default store;
